<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="PROFESSOR" />
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-md-6">
              <h3 class="page-title mb-0 text-left">
                {{objet.school.name}}
                <br/>
                [{{objet.name}} - {{nom_matiere}} ] 
                <br/>
                ( {{year_period_name}} )
                
              </h3>
            </div>
            <div class="col-md-6">
              <ul class="breadcrumb mb-0 p-0 float-right">
                <li class="breadcrumb-item">
                  <router-link to="/prof-dashboard"><i class="fas fa-home"></i> Accueil</router-link>
                </li>
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-home"></i> Etablissement</a>
                </li>
                <li class="breadcrumb-item"><span>liste eleves</span></li>
              </ul>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-sm-12 text-left">
                    <a v-if="objet.id != undefined"
                        @click="$router.go(-1)"
                        style="cursor:pointer"
                        class=" float-left"
                        >
                          &lt; Retour
                        </a>
                    <br/><br/>
                    <div class="page-title">
                     
                      
                      <h4>Enregitrer les absences en {{nom_matiere}} </h4>

                      <div class="row">
                        <div class="col-md-6">
                            <label> Heure de debut </label> &nbsp;
                            <input class="form-control" placeholder="7H30" v-model="heure_debut" />
                        </div>
                        <div class="col-md-6">
                            <label> Nombre d'heure manquée  </label> &nbsp;
                            <input class="form-control" placeholder="2" type="number" v-model="nb_heure_manque" />
                        </div>
                      </div>

                      <div class="loader" id="loading_circle_1"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table custom-table">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-left" >Nom et prenoms </th>
                        <th class="text-left" >Absent </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item_student in students" :key="item_student.id" class="text-center" >
                        <td>
                          <h2>
                            {{item_student.first_name}} {{item_student.last_name}}
                          </h2>
                        </td>
                        <td>
                          <h2>
                            <input type="checkbox" v-bind:value="item_student.id" class="item_absent" />
                          </h2>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br/>
                  
<!-- espace de notif reponse -->
                <div id="div_reponse" style="display:none">
                    <div class="alert alert-success text-center" v-if="requete_reussi == true">
                      <h4>{{ msg_success }}</h4>
                      
                    <br/>
                    <button
                      id="btn_modal"
                      type="button"
                      class="btn btn-success "
                      @click="toggle_div_reponse"
                    >
                      J'ai compris
                    </button>
                    </div>
                    
                    <div v-if="requete_reussi == false">
                      <div class="alert alert-danger">
                        <h4> Les champs suivants sont invalides : </h4>
                        <ul>
                          <li v-for="msg in message_erreur" :key="msg">
                            {{ msg }}
                          </li>
                        </ul>
                        
                        <br/>
                        <div class="text-center">
                          <button
                            id="btn_modal"
                            type="button"
                            class="btn btn-danger "
                            @click="toggle_div_reponse"
                          >
                            J'ai compris
                          </button>
                        </div>
                      </div>
                    </div>
                    
                </div>
<!-- //Fin espace notif reponse -->
                  <br/>
                  <h3 class="text-center">
                      <button 
                        class="btn btn-primary"
                        id="btn_submit"
                        @click="enregistrer_les_absences"
                      >
                         Enregistrer les absences 
                         <div class="loader" id="loading_circle_2"></div>
                      </button>
                  </h3>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../utils/Variable";
// import setAuthHeader from '../utils/setAuthHeader';

import BaseAdmin from "../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },
  data() {
    return {
      id : this.$route.params.id_classe,
      
      nom_period : this.$route.params.nom_period,
      year_period_id : this.$route.params.id_period,
      year_period_name : "",
      nom_matiere : this.$route.params.nom_matiere,
      subject_id : this.$route.params.subject_id,

      echec_connexion: false,
      
      nb_heure_manque: 1,
      heure_debut: "7H30",

      erreur_de_chargement: false,
      objet: {
        school : {}
      },
      requete_reussi:false,
      message_erreur:"",
	    students:[],
	    student_id_data:[],
    };
  },
  mounted() {
    this.getItemData();
    this.getDetailsYearPeriod();
  },
  methods: {
    toggle_div_reponse : function(){
      let div_reponse = document.getElementById('div_reponse');
      if(div_reponse.style.display == 'none'){
        div_reponse.style.display = 'block';
      }else{
        div_reponse.style.display = 'none';
      }  
    },

    hideErrorMessage: function () {
      this.echec_connexion = false;
      this.erreur_de_chargement = false;
    },

     getItemData() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.CLASSROOM_STUDENT_LIST + "/" + this.id;
      axios
        .get(route)
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.objet = response.data.data.classroom;
            this.students = response.data.data.students;
            console.log(response.data.school);
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },
    
    
    enregistrer_les_absences(){

      if(this.invalid_field()){
        return false;
      }

      document.getElementById("loading_circle_2").style.display = "inline-block";
      document.getElementById("btn_submit").disabled = true;

      var list_student_id_class = document.getElementsByClassName("item_absent");

      this.student_id_data = [];

      let index= 0;
      for(var i=0;i<list_student_id_class.length;i++){
        if(list_student_id_class[i].checked){
          this.student_id_data[index] = list_student_id_class[i].value;
          index = index +1;
        }
      }

      console.log(this.student_id_data);
  
      // alert(
      //   "class = "+this.id +
      //   "sub = "+this.subject_id +
      //   "id_period = "+this.year_period_id +
      //   "start hour = "+this.heure_debut + 
      //   "missed hour = "+this.nb_heure_manque 
      // );

      axios.post(API_URL.ENREGISTRER_ABSENCES,{
        
        classroom_id : this.id,
        subject_id : this.subject_id,
        year_period_id : this.year_period_id,
        start_hour : this.heure_debut,
        missed_hour : this.nb_heure_manque,

        student_ids : this.student_id_data,

      })
      .then((response)=>{
        
        console.log(response);
         
         this.message_erreur = [];
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur.push(key + " : " + error_array[key]);
            });
            this.requete_reussi = false;
          } else {
            this.clear_field();
            this.requete_reussi = true;
            this.msg_success = response.data.message;
          }
      }).catch(()=>{
        alert("un probleme de connexion est survenue");
      })
      .finally(()=>{
        document.getElementById("loading_circle_2").style.display = "none";
        document.getElementById("btn_submit").disabled = false;
        document.getElementById("btn_modal").click();
      });

    },

     getDetailsYearPeriod() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.GET_YEAR_PERIOD + "/" + this.year_period_id;
      axios
        .get(route)
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.year_period_name = response.data.data.name;
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },
       invalid_field() {
      if (
        this.heure_debut == null ||
        this.heure_debut == ""  ||
        this.nb_heure_manque == null ||
        this.nb_heure_manque == 0  ||
        this.nb_heure_manque == ""  
      ) {
        alert("Veuillez remplir correctement les champs requis");
        return true;
      } else {
        return false;
      }
    },
    clear_field() {
      var list_checked_item = document.getElementsByClassName("item_absent");
      for(var i=0;i<list_checked_item.length;i++){
        list_checked_item[i].checked = false;
      }
    },


  },
};
</script>
