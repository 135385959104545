<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="FONDATOR" />
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-md-6">
              <h3 class="page-title mb-0 text-left">
                Association - Prof -Matieres
                <br />
                [{{ objet.name }} - {{ objet.school.name }} ]
              </h3>
            </div>
            <div class="col-md-6">
              <ul class="breadcrumb mb-0 p-0 float-right">
                <li class="breadcrumb-item">
                  <router-link to="/fondator-dashboard"
                    ><i class="fas fa-home"></i> Accueil</router-link
                  >
                </li>
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-home"></i> Etablissement</a>
                </li>
                <li class="breadcrumb-item"><span>liste eleves</span></li>
              </ul>
            </div>
          </div>
        </div>

        <!-- espace de notif reponse -->
        <div id="div_reponse" style="display: none">
          <div
            class="alert alert-success text-center"
            v-if="requete_reussi == true"
          >
            <h4>{{ msg_success }}</h4>

            <br />
            <button
              id="btn_modal"
              type="button"
              class="btn btn-success"
              @click="toggle_div_reponse"
            >
              J'ai compris
            </button>
          </div>

          <div v-if="requete_reussi == false">
            <div class="alert alert-danger">
              <h4>Les champs suivants sont invalides :</h4>
              <ul>
                <li v-for="msg in message_erreur" :key="msg">
                  {{ msg }}
                </li>
              </ul>

              <br />
              <div class="text-center">
                <button
                  id="btn_modal"
                  type="button"
                  class="btn btn-danger"
                  @click="toggle_div_reponse"
                >
                  J'ai compris
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- //Fin espace notif reponse -->

        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-sm-6 text-left">
                    <a @click="$router.go(-1)" href="#"> &lt; Retour</a>
                    <br />
                    <br />
                    <div class="page-title">
                      <button
                        data-toggle="modal"
                        data-target="#modal_rattacher"
                        class="btn btn-primary btn-rounded float-left"
                      >
                        <i class="fas fa-plus"></i> ajouter des matieres
                      </button>
                      <div class="loader" id="loading_circle_1"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table custom-table">
                    <thead class="thead-light">
                      <tr>
                        <th>Matieres</th>
                        <th>Professeur</th>
                        <th class="text-right">#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in objet.professors_subject"
                        :key="item.id"
                        class="text-center"
                      >
                        <td>
                          {{ item.subject.name }}
                        </td>
                        <td>
                          {{ item.professor.first_name }}
                          {{ item.professor.last_name }}
                        </td>
                        <td class="text-right">
                          <button
                            type="button"
                            data-toggle="modal"
                            v-bind:data-target="'#modal_detacher_' + item.id"
                            class="btn btn-danger btn-sm mb-1"
                          >
                            <i class="far fa-trash-alt"></i> Retirer la Matiere
                          </button>
                          <!-- MODAL EFFACER -->

                          <div
                            class="modal custom-modal fade"
                            v-bind:id="'modal_detacher_' + item.id"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h3>
                                    Rattacher des professeurs aux matieres
                                  </h3>
                                </div>
                                <div class="modal-body">
                                  <div class="alert alert-danger">
                                    <h5 class="text-center">
                                      <b>ACTION IRREVERSIBLE !! </b>
                                      <br />
                                      Confirmer la <b>retrait</b> de la matiere
                                      :
                                      <b class="text-uppercase">{{
                                        item.subject.name
                                      }}</b>
                                      <br />
                                      enseignée par le Professeur <br />
                                      <b class="text-uppercase"
                                        >{{ item.professor.first_name }}
                                        {{ item.professor.last_name }}</b
                                      >
                                      de la classe de
                                      <b class="text-uppercase">{{
                                        objet.name
                                      }}</b>
                                    </h5>
                                  </div>

                                  <div class="col-12 text-center">
                                    <button
                                      class="btn btn-default"
                                      data-dismiss="modal"
                                      aria-hidden="true"
                                    >
                                      NON
                                    </button>
                                    &nbsp;
                                    <button
                                      class="btn btn-danger"
                                      type="button"
                                      data-dismiss="modal"
                                      aria-hidden="true"
                                      @click="supprimer_item(item.id)"
                                    >
                                      CONFIRMER
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- // MODAL EFFACER -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL ASSOCIATION -->

    <div class="modal custom-modal fade" id="modal_rattacher">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h3>Rattacher des professeurs aux matieres</h3>
          </div>
          <div class="modal-body">
            <div style="height: 350px; overflow: scroll">
              <table class="table custom-table">
                <thead class="thead-light">
                  <tr>
                    <th>Matieres</th>
                    <th class="text-left">Professeur</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item_subject in subjects"
                    :key="item_subject.id"
                    class="text-center"
                  >
                    <td>
                      <select class="form-control">
                        <option
                          v-bind:value="item_subject.id"
                          class="subject_id"
                        >
                          {{ item_subject.name }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <select class="form-control profesor_id">
                        <option value="0">--Selectionner--</option>
                        <option
                          v-for="item_professor in professors"
                          v-bind:value="item_professor.id"
                          :key="item_professor.id"
                        >
                          {{ item_professor.first_name }}
                          {{ item_professor.last_name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <br />
            <div class="row text-center">
              <button
                class="btn btn-primary"
                id="btn_submit"
                @click="attachMultipleSubject"
              >
                Enregistrer
                <div class="loader" id="loading_circle_4"></div>
              </button>
              &nbsp;&nbsp;
              <button
                class="close"
                id="btn_close_modal"
                data-dismiss="modal"
                aria-hidden="true"
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../utils/Variable";
// import setAuthHeader from '../utils/setAuthHeader';

import BaseAdmin from "../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },
  data() {
    return {
      id: this.$route.params.id_classe,
      new_paiement_amount: null,
      echec_connexion: false,
      erreur_de_chargement: false,
      objet: {
        school: {},
      },
      requete_reussi: false,
      message_erreur: "",
      subjects: [],
      professors: [],

      subject_id_list: [],
      classroom_id_list: [],
      prof_id_list: [],
    };
  },
  mounted() {
    this.getItemData();
    this.getSubjectList();
  },
  methods: {
    toggle_div_reponse: function () {
      let div_reponse = document.getElementById("div_reponse");
      if (div_reponse.style.display == "none") {
        div_reponse.style.display = "block";
      } else {
        div_reponse.style.display = "none";
      }
    },

    hideErrorMessage: function () {
      this.echec_connexion = false;
      this.erreur_de_chargement = false;
    },

    getItemData() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.CLASSROOM_PROFESSOR_SUBJECT + "/" + this.id;
      axios
        .get(route)
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.objet = response.data.data.classroom;
            this.getProfList(this.objet.school_id);
            // console.log(response.data.classroom);
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

    getProfList(school_id) {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      axios
        .get(API_URL.READ_SCHOOL + "/" + school_id + "/profesors")
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.professors = response.data.data.profesors;
            console.log(response.data.data.profesors);
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

    getSubjectList() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      axios
        .get(API_URL.ALL_SUBJECT)
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.subjects = response.data.data.subjects;
            console.log(response.data.data.subjects);
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

    attachMultipleSubject() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_4").style.display =
        "inline-block";

      var list_subject_id = document.getElementsByClassName("subject_id");
      var list_prof_id = document.getElementsByClassName("profesor_id");

      for (var i = 0; i < list_subject_id.length; i++) {
        this.subject_id_list[i] = list_subject_id[i].value;
        this.classroom_id_list[i] = this.id;
        this.prof_id_list[i] = list_prof_id[i].value;
      }

      axios
        .post(API_URL.ATTACH_SUBJECT_TO_CLASSROOM, {
          subject_id: this.subject_id_list,
          classroom_id: this.classroom_id_list,
          professor_id: this.prof_id_list,
        })
        .then((response) => {
          console.log(response);

          this.message_erreur = [];
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur.push(key + " : " + error_array[key]);
            });
            this.requete_reussi = false;
          } else {
            this.requete_reussi = true;
            this.msg_success = response.data.message;
            this.getItemData();
          }
        })
        .catch(() => {
          alert("un probleme de connexion est survenue");
        })
        .finally(() => {
          document.getElementById("loading_circle_4").style.display = "none";
          document.getElementById("btn_submit").disabled = false;
          document.getElementById("btn_modal").click();
          document.getElementById("btn_close_modal").click();
        });
    },
    supprimer_item: function (id) {
      document.getElementById("loading_circle_1").style.display =
        "inline_block";
      axios
        .delete(API_URL.EFFACER_LIEN_PROF_MATIERE + "/" + id)
        .then((response) => {
          document.getElementById("loading_circle_1").style.display = "none";

          this.message_erreur = [];
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur.push(key + " : " + error_array[key]);
            });
            this.requete_reussi = false;
          } else {
            this.requete_reussi = true;
            this.msg_success = response.data.message;
            alert(response.data.message);
            this.getItemData();
          }
        })
        .catch(() => {
          this.erreur_de_chargement = true;
          document.getElementById("loading_circle_1").style.display = "none";
          document.getElementById("btn_modal").click();
        });
    },
  },
};
</script>
