import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";

import AdminDashboard from "../views/ESPACE_UTILISATEUR/ADMIN/dashboard";
import FondatorDashboard from "../views/ESPACE_UTILISATEUR/FONDATOR/dashboard";
import ProfDashboard from "../views/ESPACE_UTILISATEUR/PROFESSOR/dashboard";
import ParentDashboard from "../views/ESPACE_UTILISATEUR/PARENT/dashboard";

import AdminListeFondateur from "../views/ESPACE_UTILISATEUR/ADMIN/Utilisateurs/liste-fondateur";
import AdminListeAdjoints from "../views/ESPACE_UTILISATEUR/ADMIN/Utilisateurs/liste-adjoints";
import AdminListeEtablissement from "../views/ESPACE_UTILISATEUR/ADMIN/liste-etablissements";

import AdminAjouterEcoles from "../views/ESPACE_UTILISATEUR/ADMIN/ajouter-etablissements";
import AdminModifierEcoles from "../views/ESPACE_UTILISATEUR/ADMIN/modifier-etablissements";

import AdminAjouterUtilisateur from "../views/ESPACE_UTILISATEUR/ADMIN/Utilisateurs/ajouter";
import AdminEditerUtilisateur from "../views/ESPACE_UTILISATEUR/ADMIN/Utilisateurs/editer";
import AdminModifierProfil from "../views/ESPACE_UTILISATEUR/modifier-mon-profil";

// -------FONDATOR
import FondatorModifierEcoles from "../views/ESPACE_UTILISATEUR/FONDATOR/modifier-etablissements";
import FondatorDetailsEcoles from "../views/ESPACE_UTILISATEUR/FONDATOR/details-etablissements";
import FondatorDetailsClasseEcoles from "../views/ESPACE_UTILISATEUR/FONDATOR/details-etablissements-classes";
import FondatorListeProfesseurEcoles from "../views/ESPACE_UTILISATEUR/FONDATOR/liste-professeurs";

import FondatorVuePivotProf from "../views/ESPACE_UTILISATEUR/FONDATOR/pivot/vue-pivot-prof";
import FondatorVuePivotMatiere from "../views/ESPACE_UTILISATEUR/FONDATOR/pivot/vue-pivot-matiere";
import FondatorVuePivotEleves from "../views/ESPACE_UTILISATEUR/FONDATOR/pivot/vue-pivot-eleves";
import FondatorVuePivotClassesEtMatieres from "../views/ESPACE_UTILISATEUR/FONDATOR/pivot/vue-pivot-classe-et-frais";

import FondatorAjouterProfesseurEcoles from "../views/ESPACE_UTILISATEUR/FONDATOR/ajouter-professeur";
import FondatorEditerProfesseurEcoles from "../views/ESPACE_UTILISATEUR/FONDATOR/editer-professeur";

import FondatorListeElevesEcoles from "../views/ESPACE_UTILISATEUR/FONDATOR/liste-eleves";
import FondatorAjouterElevesEcoles from "../views/ESPACE_UTILISATEUR/FONDATOR/ajouter-eleve";
import FondatorEditerElevesEcoles from "../views/ESPACE_UTILISATEUR/FONDATOR/editer-eleve";

import FondatorAssociationProfMatiereClasse from "../views/ESPACE_UTILISATEUR/FONDATOR/association-prof-matiere";

// ---PROF
import ProfessorPivotAddNotes from "../views/ESPACE_UTILISATEUR/PROFESSOR/pivot/pivot-notes";
import ProfessorPivotAddAbsences from "../views/ESPACE_UTILISATEUR/PROFESSOR/pivot/pivot-absences";
import ProfessorPivotMoyennes from "../views/ESPACE_UTILISATEUR/PROFESSOR/pivot/pivot-moyennes";
import ProfessorPivotBilanAbsence from "../views/ESPACE_UTILISATEUR/PROFESSOR/pivot/pivot-bilan-absences";

import ProfessorListeEleveClasse from "../views/ESPACE_UTILISATEUR/PROFESSOR/liste-eleves";
import ProfessorEnregistrerNotes from "../views/ESPACE_UTILISATEUR/PROFESSOR/enregistrer-notes-eleves";
import ProfessorEnregistrerAbsences from "../views/ESPACE_UTILISATEUR/PROFESSOR/enregistrer-absences-eleves";
import ProfessorConsulterMoyennes from "../views/ESPACE_UTILISATEUR/PROFESSOR/consulter-moyennes-eleves";
import ProfessorConsulterAbsences from "../views/ESPACE_UTILISATEUR/PROFESSOR/consulter-absences-eleves";

//---PARENT
import ParentBulletinEleve from "../views/ESPACE_UTILISATEUR/PARENT/bulletin-eleve";

// import $ from 'jquery';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: "/connexion",
    name: "Login",
    component: Login,
  },

  //ESPACE ADMIN
  {
    path: "/admin-dashboard",
    name: "admin-dashboard",
    component: AdminDashboard,
  },
  {
    path: "/fondator-dashboard",
    name: "fondator-dashboard",
    component: FondatorDashboard,
  },
  {
    path: "/prof-dashboard",
    name: "prof-dashboard",
    component: ProfDashboard,
  },
  {
    path: "/parent-dashboard",
    name: "parent-dashboard",
    component: ParentDashboard,
  },

  //======================ADMIN=======================

  //UTILISATEUR
  {
    path: "/admin-modifier-profil",
    name: "admin-modifier-profil",
    component: AdminModifierProfil,
  },

  {
    path: "/admin-liste-adjoints",
    name: "admin-liste-adjoints",
    component: AdminListeAdjoints,
  },

  {
    path: "/admin-liste-fondateurs",
    name: "admin-liste-fondateurs",
    component: AdminListeFondateur,
  },

  {
    path: "/admin-liste-etablissements",
    name: "admin-liste-etablissements",
    component: AdminListeEtablissement,
  },

  {
    path: "/admin-ajouter-etablissements/:user_type?",
    name: "admin-ajouter-etablissements",
    component: AdminAjouterEcoles,
  },

  {
    path: "/admin-editer-etablissements/:id?",
    name: "admin-editer-etablissements",
    component: AdminModifierEcoles,
  },

  {
    path: "/admin-ajouter-utilisateur/:user_type?",
    name: "admin-ajouter-utilisateur",
    component: AdminAjouterUtilisateur,
  },

  {
    path: "/admin-editer-utilisateur/:id?",
    name: "admin-editer-utilisateur",
    component: AdminEditerUtilisateur,
  },

  //====================FONDATOR=========================
  //  -------Pivot
  {
    path: "/fondator-pivot-professeurs",
    name: "fondator-pivot-prof",
    component: FondatorVuePivotProf,
  },
  {
    path: "/fondator-pivot-matieres-enseignees",
    name: "fondator-pivot-matieres-enseignees",
    component: FondatorVuePivotMatiere,
  },
  {
    path: "/fondator-pivot-prof-eleves",
    name: "fondator-pivot-eleves",
    component: FondatorVuePivotEleves,
  },
  {
    path: "/fondator-pivot-classes",
    name: "fondator-pivot-classes",
    component: FondatorVuePivotClassesEtMatieres,
  },
  //  -------Pivot

  {
    path: "/fondator-editer-etablissements/:id",
    name: "fondator-editer-etablissements",
    component: FondatorModifierEcoles,
  },

  {
    path: "/fondator-details-etablissements/:id",
    name: "fondator-details-etablissements",
    component: FondatorDetailsEcoles,
  },

  {
    path: "/fondator-frais-classe/:id_etablissement",
    name: "fondator-frais-classe",
    component: FondatorDetailsClasseEcoles,
  },

  {
    path: "/fondator-liste-professeur-etablissement/:id_etablissement",
    name: "fondator-liste-professeur-etablissement",
    component: FondatorListeProfesseurEcoles,
  },

  {
    path: "/fondator-ajouter-professeur-etablissement/:id_etablissement",
    name: "fondator-ajouter-professeur-etablissement",
    component: FondatorAjouterProfesseurEcoles,
  },

  {
    path: "/fondator-editer-professeur-etablissement/:id_prof",
    name: "fondator-editer-professeur-etablissement",
    component: FondatorEditerProfesseurEcoles,
  },

  {
    path: "/fondator-liste-eleves-etablissement/:id_classe",
    name: "fondator-liste-eleves-etablissement",
    component: FondatorListeElevesEcoles,
  },

  {
    path: "/fondator-ajouter-eleves-etablissement/:id_classe",
    name: "fondator-ajouter-eleves-etablissement",
    component: FondatorAjouterElevesEcoles,
  },

  {
    path: "/fondator-editer-eleves-etablissement/:id_eleve",
    name: "fondator-editer-eleves-etablissement",
    component: FondatorEditerElevesEcoles,
  },
  {
    path: "/fondator-association-prof-matiere-classes/:id_classe",
    name: "fondator-association-prof-matiere-classes",
    component: FondatorAssociationProfMatiereClasse,
  },

  //======================PROFESSOR=======================

  //---pivot
  {
    path: "/professor-pivot-notes",
    name: "professor-pivot-notes",
    component: ProfessorPivotAddNotes,
  },
  {
    path: "/professor-pivot-absences",
    name: "professor-pivot-absences",
    component: ProfessorPivotAddAbsences,
  },
  {
    path: "/professor-pivot-moyennes",
    name: "professor-pivot-moyennes",
    component: ProfessorPivotMoyennes,
  },
  {
    path: "/professor-pivot-bilan-moyennes",
    name: "professor-pivot-bilan-moyennes",
    component: ProfessorPivotBilanAbsence,
  },
  //--pivot
  {
    path: "/professor-liste-eleves/:id_classe/:nom_matiere/:subject_id",
    name: "professor-liste-eleves",
    component: ProfessorListeEleveClasse,
  },

  {
    path: "/professor-enregistrer-notes/:id_classe/:nom_matiere/:subject_id/:id_period",
    name: "professor-enregistrer-notes",
    component: ProfessorEnregistrerNotes,
  },

  {
    path: "/professor-enregistrer-absences/:id_classe/:nom_matiere/:subject_id/:id_period",
    name: "professor-enregistrer-absences",
    component: ProfessorEnregistrerAbsences,
  },

  {
    path: "/professor-consulter-moyennes/:id_classe/:nom_matiere/:subject_id/:id_period",
    name: "professor-consulter-moyennes",
    component: ProfessorConsulterMoyennes,
  },

  {
    path: "/professor-consulter-absences/:id_classe/:nom_matiere/:subject_id/:id_period",
    name: "professor-consulter-absences",
    component: ProfessorConsulterAbsences,
  },

  // ============================PARENT

  {
    path: "/parent-bulletin-eleve/:id_eleve/:id_classe",
    name: "parent-bulletin-eleve",
    component: ParentBulletinEleve,
  },
];

// $.holdReady(true);
const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
