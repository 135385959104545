<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="FONDATOR" />

    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
              <h5 class="text-uppercase mb-0 mt-0 page-title text-left">
                Ajouter un etablissement
              </h5>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
              <ul class="breadcrumb float-right p-0 mb-0">
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-home"></i> Acceuil</a>
                </li>
                <li class="breadcrumb-item"><a href="#">Etablissements</a></li>
                <li class="breadcrumb-item"><span>Ajouter</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header text-left">
                <router-link to="/admin-liste-etablissements">
                  &lt; Retour a la liste
                </router-link>

                <div class="loader" id="loading_circle_1"></div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <div class="page-content">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="form-group">
                                  <label>Fondateur</label>
                                  <select
                                    class="form-control"
                                    v-model="id_fondateur"
                                  >
                                    <option
                                      v-for="item_fondator in fondateurs"
                                      :key="item_fondator.id"
                                      v-bind:value="item_fondator.id"
                                    >
                                      {{ item_fondator.last_name }}
                                      {{ item_fondator.first_name }}
                                    </option>
                                  </select>
                                </div>
                                <div class="form-group">
                                  <label>Nom de l'etablissement</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="name"
                                  />
                                </div>
                                <div class="form-group">
                                  <label>Pays</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="pays"
                                  />
                                </div>
                                <div class="form-group">
                                  <label>Ville</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="ville"
                                  />
                                </div>

                                <div class="form-group">
                                  <label>Commune</label>
                                  <input
                                    class="form-control"
                                    v-model="province_commune"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="form-group">
                                  <label>Addresse</label>
                                  <input
                                    class="form-control"
                                    v-model="address"
                                    type="text"
                                  />
                                </div>

                                <br />
                                <h5 class="text-left">
                                  <b>{{ name }}</b> est un etablissement de :
                                </h5>
                                <div class="form-group">
                                  <label>Premier cycle ? </label>
                                  <select
                                    class="form-control"
                                    v-model="is_first_cycle"
                                  >
                                    <option value="1">OUI</option>
                                    <option value="0">NON</option>
                                  </select>
                                </div>
                                <div class="form-group">
                                  <label>Second cycle ? </label>
                                  <select
                                    class="form-control"
                                    v-model="is_second_cycle"
                                  >
                                    <option value="1">OUI</option>
                                    <option value="0">NON</option>
                                  </select>
                                </div>
                              </div>

                              <div class="col-12 text-center">
                                <!-- espace de notif reponse -->
                                <div id="div_reponse" style="display: none">
                                  <div
                                    class="alert alert-success text-center"
                                    v-if="requete_reussi == true"
                                  >
                                    <h4>{{ msg_success }}</h4>

                                    <br />
                                    <button
                                      id="btn_modal"
                                      type="button"
                                      class="btn btn-success"
                                      @click="toggle_div_reponse"
                                    >
                                      J'ai compris
                                    </button>
                                  </div>

                                  <div v-if="requete_reussi == false">
                                    <div class="alert alert-danger">
                                      <h4>
                                        Les champs suivants sont invalides :
                                      </h4>
                                      <ul>
                                        <li
                                          v-for="msg in message_erreur"
                                          :key="msg"
                                        >
                                          {{ msg }}
                                        </li>
                                      </ul>

                                      <br />
                                      <div class="text-center">
                                        <button
                                          id="btn_modal"
                                          type="button"
                                          class="btn btn-danger"
                                          @click="toggle_div_reponse"
                                        >
                                          J'ai compris
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- //Fin espace notif reponse -->
                                <div
                                  class="
                                    form-group
                                    text-center
                                    custom-mt-form-group
                                  "
                                >
                                  <button
                                    class="btn btn-primary mr-2"
                                    @click="submit_form"
                                    type="button"
                                    id="btn_submit"
                                  >
                                    Enregistrer l'etablissement
                                  </button>

                                  <div
                                    class="loader"
                                    id="loading_circle_2"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../utils/Variable";
import BaseAdmin from "../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },

  data() {
    return {
      name: "",
      pays: "",
      ville: "",
      province_commune: "",
      address: "",
      is_first_cycle: 1,
      is_second_cycle: 1,

      id_fondateur: 0,
      msg_success: "",
      erreur_de_chargement: false,
      requete_reussi: false,
      message_erreur: [],
      fondateurs: [],
    };
  },
  mounted() {
    this.getListFondateur();
  },
  methods: {
    toggle_div_reponse: function () {
      let div_reponse = document.getElementById("div_reponse");
      if (div_reponse.style.display == "none") {
        div_reponse.style.display = "block";
      } else {
        div_reponse.style.display = "none";
      }
    },

    submit_form: function () {
      if (this.invalid_field()) {
        return false;
      }

      document.getElementById("loading_circle_2").style.display =
        "inline-block";
      document.getElementById("btn_submit").disabled = true;

      axios
        .post(API_URL.ENREGISTRER_SCHOOL, {
          fondator_id: parseInt(this.id_fondateur),
          name: this.name,
          pays: this.pays,
          ville: this.ville,
          province_commune: this.province_commune,
          address: this.address,
          is_first_cycle: this.is_first_cycle,
          is_second_cycle: this.is_second_cycle,
        })
        .then((response) => {
          console.log(response);

          this.message_erreur = [];
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur.push(key + " : " + error_array[key]);
            });
            this.requete_reussi = false;
          } else {
            this.requete_reussi = true;
            this.msg_success = response.data.message;
            this.clear_field();
          }
        })
        .catch(() => {
          alert("un probleme de connexion est survenue");
        })
        .finally(() => {
          document.getElementById("loading_circle_2").style.display = "none";
          document.getElementById("btn_submit").disabled = false;
          document.getElementById("btn_modal").click();
        });
    },
    getListFondateur() {
      document.getElementById("loading_circle_1").style.display =
        "inline-block";
      this.erreur_de_chargement = false;
      axios
        .get(API_URL.LISTE_FONDATEUR)
        .then((response) => {
          this.fondateurs = response.data.data.users;
          // alert(response.data.message);
          console.log(response.data.data);
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          console.log(error);
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

    invalid_field() {
      if (
        this.fondator_id < 0 ||
        this.fondator_id == "" ||
        this.name == "" ||
        this.pays == "" ||
        this.ville == "" ||
        this.province_commune == ""
      ) {
        alert("Veuillez remplir correctement les champs requis");
        return true;
      } else {
        return false;
      }
    },
    clear_field() {
      (this.name = ""),
        (this.pays = ""),
        (this.ville = ""),
        (this.province_commune = "");
    },
  },
};
</script>

<style scoped>
.form-group {
  text-align: left;
}
</style>