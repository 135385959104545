<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="FONDATOR" />
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-md-6">
              <h3 class="page-title mb-0 text-left">
                Liste des professeurs [{{ objet.name }}]
              </h3>
            </div>
            <div class="col-md-6">
              <ul class="breadcrumb mb-0 p-0 float-right">
                <li class="breadcrumb-item">
                  <router-link to="/fondator-dashboard"
                    ><i class="fas fa-home"></i> Accueil</router-link
                  >
                </li>
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-home"></i> Etablissement</a>
                </li>
                <li class="breadcrumb-item"><span>liste professeurs</span></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-sm-6 text-left">
                    <router-link
                      v-if="objet.id != undefined"
                      v-bind:to="'/fondator-details-etablissements/' + objet.id"
                      class="float-left"
                    >
                      &lt; Retour
                    </router-link>
                    <br /><br />
                    <div class="page-title">
                      <router-link
                        v-if="objet.id != undefined"
                        v-bind:to="
                          '/fondator-ajouter-professeur-etablissement/' +
                          objet.id
                        "
                        class="btn btn-primary btn-rounded float-left"
                        ><i class="fas fa-plus"></i> Ajouter un
                        nouveau</router-link
                      >
                      <div class="loader" id="loading_circle_1"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table custom-table">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-left">Nom et prenoms</th>
                        <th>Telephone</th>
                        <th class="text-right">#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item_profesor in objet.profesor"
                        :key="item_profesor.id"
                        class="text-center"
                      >
                        <td>
                          <h2>
                            {{ item_profesor.first_name }}
                            {{ item_profesor.last_name }}
                          </h2>
                        </td>
                        <td>
                          {{ item_profesor.prefix_mobile }}
                          {{ item_profesor.mobile }}
                        </td>
                        <td class="text-right">
                          <router-link
                            v-bind:to="
                              '/fondator-editer-professeur-etablissement/' +
                              item_profesor.id
                            "
                            class="btn btn-secondary btn-sm mb-1"
                          >
                            <i class="far fa-edit"></i>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../utils/Variable";
// import setAuthHeader from '../utils/setAuthHeader';

import BaseAdmin from "../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },
  data() {
    return {
      id: this.$route.params.id_etablissement,
      prefix_mobile: 225,
      mobile: null,
      password: null,
      echec_connexion: false,
      erreur_de_chargement: false,
      objet: {
        nb_ecole: 0,
        nb_eleves: 0,
        nb_prof: 0,
      },
      profesors: [],
    };
  },
  mounted() {
    this.getItemData();
  },
  methods: {
    hideErrorMessage: function () {
      this.echec_connexion = false;
      this.erreur_de_chargement = false;
    },

    getItemData() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      axios
        .get(API_URL.READ_SCHOOL + "/" + this.id + "?with-profesor=1")
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.objet = response.data.data.school;
            if (this.objet.is_second_cycle == true) {
              this.objet.is_second_cycle = 1;
            } else {
              this.objet.is_second_cycle = 0;
            }
            console.log(response.data.school);
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },
  },
};
</script>
