<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="FONDATOR" />

    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
              <h5 class="text-uppercase mb-0 mt-0 page-title text-left">
                Modifier un etablissement
              </h5>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
              <ul class="breadcrumb float-right p-0 mb-0">
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-home"></i> Acceuil</a>
                </li>
                <li class="breadcrumb-item"><a href="#">Etablissements</a></li>
                <li class="breadcrumb-item"><span>Editer</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header text-left">
                <router-link to="/admin-liste-etablissements">
                  &lt; Retour a la liste
                </router-link>

                <div class="loader" id="loading_circle_1"></div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <div class="page-content">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="form-group">
                                  <label>Fondateur</label>
                                  <select class="form-control">
                                    <option v-bind:value="objet.user.id">
                                      {{ objet.user.last_name }}
                                      {{ objet.user.first_name }}
                                    </option>
                                  </select>
                                </div>

                                <div class="form-group">
                                  <label>Nom de l'etablissement</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="objet.name"
                                  />
                                </div>
                                <div class="form-group">
                                  <label>Pays</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="objet.pays"
                                  />
                                </div>
                                <div class="form-group">
                                  <label>Ville</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="objet.ville"
                                  />
                                </div>

                                <div class="form-group">
                                  <label>Commune</label>
                                  <input
                                    class="form-control"
                                    v-model="objet.province_commune"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="form-group">
                                  <label>Addresse</label>
                                  <input
                                    class="form-control"
                                    v-model="objet.address"
                                    type="text"
                                  />
                                </div>

                                <br />
                                <h5 class="text-left">
                                  <b>{{ objet.name }}</b> est un etablissement
                                  de :
                                </h5>
                                <div class="form-group">
                                  <label>Premier cycle ? </label>
                                  <select
                                    class="form-control"
                                    v-model="objet.is_first_cycle"
                                    disabled
                                  >
                                    <option v-bind:value="objet.is_first_cycle">
                                      {{
                                        objet.is_first_cycle == 1
                                          ? "OUI"
                                          : "NON"
                                      }}
                                    </option>
                                  </select>
                                </div>
                                <div class="form-group">
                                  <label>Second cycle ? </label>
                                  <select
                                    class="form-control"
                                    v-model="objet.is_second_cycle"
                                    disabled
                                  >
                                    <option
                                      v-bind:value="objet.is_second_cycle"
                                    >
                                      {{
                                        objet.is_second_cycle == 1
                                          ? "OUI"
                                          : "NON"
                                      }}
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div class="col-12 text-center">
                                <div class="row text-center">
                                  <!-- espace de notif reponse -->
                                  <div
                                    id="div_reponse"
                                    class="col-12"
                                    style="display: none"
                                  >
                                    <div
                                      class="alert alert-success text-center"
                                      v-if="requete_reussi == true"
                                    >
                                      <h4>{{ msg_success }}</h4>

                                      <br />
                                      <button
                                        id="btn_modal"
                                        type="button"
                                        class="btn btn-success"
                                        @click="toggle_div_reponse"
                                      >
                                        J'ai compris
                                      </button>
                                    </div>

                                    <div v-if="requete_reussi == false">
                                      <div class="alert alert-danger">
                                        <h4>
                                          Les champs suivants sont invalides :
                                        </h4>
                                        <ul>
                                          <li
                                            v-for="msg in message_erreur"
                                            :key="msg"
                                          >
                                            {{ msg }}
                                          </li>
                                        </ul>

                                        <br />
                                        <div class="text-center">
                                          <button
                                            id="btn_modal"
                                            type="button"
                                            class="btn btn-danger"
                                            @click="toggle_div_reponse"
                                          >
                                            J'ai compris
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- //Fin espace notif reponse -->

                                  <div
                                    class="
                                      form-group
                                      text-center
                                      custom-mt-form-group
                                      col-md-6
                                    "
                                  >
                                    <button
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#modal_supprimer"
                                      class="btn btn-danger"
                                      id="btn_delete"
                                    >
                                      Supprimer l'etablissement
                                      <div
                                        class="loader"
                                        id="loading_circle_3"
                                      ></div>
                                    </button>
                                    <div
                                      class="modal custom-modal fade"
                                      id="modal_supprimer"
                                    >
                                      <div
                                        class="
                                          modal-dialog modal-dialog-centered
                                        "
                                      >
                                        <div class="modal-content">
                                          <div class="modal-header">
                                            <h4 class="modal-title">Reponse</h4>
                                            <button
                                              type="button"
                                              class="close"
                                              data-dismiss="modal"
                                              aria-hidden="true"
                                            >
                                              &times;
                                            </button>
                                          </div>
                                          <div class="modal-body">
                                            <div class="alert alert-danger">
                                              <h4>
                                                ACTION IRREVERSIBLE !! <br />
                                                Confirmer la suppression de
                                                l'etablissement
                                                <br />
                                                <b>{{ objet.name }}</b>
                                                ?
                                              </h4>
                                            </div>

                                            <div class="col-12 text-center">
                                              <button
                                                class="btn btn-default"
                                                data-dismiss="modal"
                                                aria-hidden="true"
                                              >
                                                NON
                                              </button>
                                              &nbsp;
                                              <button
                                                class="btn btn-danger"
                                                data-dismiss="modal"
                                                aria-hidden="true"
                                                @click="supprimer_item"
                                              >
                                                CONFIRMER
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="
                                      form-group
                                      text-center
                                      custom-mt-form-group
                                      col-md-6
                                      text-center
                                    "
                                  >
                                    <button
                                      class="btn btn-primary mr-2"
                                      @click="submit_form"
                                      type="button"
                                      id="btn_submit"
                                    >
                                      Enregistrer l'etablissement
                                    </button>

                                    <div
                                      class="loader"
                                      id="loading_circle_2"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../utils/Variable";
import BaseAdmin from "../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },

  data() {
    return {
      id: this.$route.params.id,
      objet: {
        is_first_cycle: 1,
        is_second_cycle: 1,
        address: "",
        user: {},
      },
      msg_success: "",
      erreur_de_chargement: false,
      requete_reussi: false,
      message_erreur: [],
    };
  },
  mounted() {
    this.getItemData();
  },
  methods: {
    toggle_div_reponse: function () {
      let div_reponse = document.getElementById("div_reponse");
      if (div_reponse.style.display == "none") {
        div_reponse.style.display = "block";
      } else {
        div_reponse.style.display = "none";
      }
    },

    getItemData() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";
      document.getElementById("loading_circle_2").style.display =
        "inline-block";
      document.getElementById("btn_submit").disabled = true;

      axios
        .get(API_URL.READ_SCHOOL + "/" + this.id + "?with-fondator=1")
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.objet = response.data.data.school;
            if (this.objet.is_second_cycle == true) {
              this.objet.is_second_cycle = 1;
            } else {
              this.objet.is_second_cycle = 0;
            }
            console.log(response.data.school);
          }
          document.getElementById("loading_circle_1").style.display = "none";
          document.getElementById("loading_circle_2").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
          document.getElementById("btn_submit").disabled = false;
        });
    },
    submit_form: function () {
      if (this.invalid_field()) {
        return false;
      }

      document.getElementById("loading_circle_2").style.display =
        "inline-block";
      document.getElementById("btn_submit").disabled = true;

      axios
        .post(API_URL.ENREGISTRER_SCHOOL + "/" + this.id + "/update", {
          name: this.objet.name,
          pays: this.objet.pays,
          ville: this.objet.ville,
          province_commune: this.objet.province_commune,
          address: this.objet.address.toString(),
        })
        .then((response) => {
          console.log(response);

          this.message_erreur = [];
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur.push(key + " : " + error_array[key]);
            });
            this.requete_reussi = false;
          } else {
            this.requete_reussi = true;
            this.msg_success = response.data.message;
            this.clear_field();
          }
        })
        .catch(() => {
          alert("un probleme de connexion est survenue");
        })
        .finally(() => {
          document.getElementById("loading_circle_2").style.display = "none";
          document.getElementById("btn_submit").disabled = false;
          document.getElementById("btn_modal").click();
        });
    },
    supprimer_item: function () {
      document.getElementById("loading_circle_3").style.display =
        "inline_block";
      document.getElementById("btn_delete").disabled = true;

      axios
        .delete(API_URL.EFFACER_ETABLISSEMENT + "/" + this.id)
        .then((response) => {
          this.message_erreur = [];
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur.push(key + " : " + error_array[key]);
            });
            this.CONTENU_MODAL = this.message_erreur;
            this.requete_reussi = false;
            document.getElementById("btn_modal").click();
          } else {
            alert(response.data.message);
            this.$router.replace("/admin-liste-etablissements");
          }
        })
        .catch(() => {
          this.erreur_de_chargement = true;
        })
        .finally(() => {
          document.getElementById("btn_delete").disabled = false;
          document.getElementById("loading_circle_3").style.display = "none";
        });
    },
    invalid_field() {
      if (
        this.name == "" ||
        this.pays == "" ||
        this.ville == "" ||
        this.province_commune == ""
      ) {
        alert("Veuillez remplir correctement les champs requis");
        return true;
      } else {
        return false;
      }
    },
    clear_field() {
      (this.name = ""),
        (this.pays = ""),
        (this.ville = ""),
        (this.province_commune = "");
    },
  },
};
</script>

<style scoped>
.form-group {
  text-align: left;
}
</style>