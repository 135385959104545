<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="ADMIN" />

    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-md-6">
              <h3 class="page-title mb-0 text-left">
                Tableau de Bord
                <div class="loader" id="loading_circle_1"></div>
              </h3>
            </div>
            <div class="col-md-6">
              <ul class="breadcrumb mb-0 p-0 float-right">
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-home"></i> Home</a>
                </li>
                <li class="breadcrumb-item"><span>Dashboard</span></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
          <div
            class="col-md-6 col-sm-6 col-lg-6 col-xl-4"
            v-if="!is_supervisor"
          >
            <router-link to="/admin-liste-adjoints">
              <div class="dash-widget dash-widget5">
                <span class="float-left"
                  ><img
                    src="template_admin/img/dash/dash-1.png"
                    alt=""
                    width="80"
                /></span>
                <div class="dash-widget-info text-right">
                  <span>Adjoints </span>
                  <h3>{{ objet.nb_adjoint }}</h3>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-6 col-sm-6 col-lg-6 col-xl-4">
            <router-link to="/admin-liste-fondateurs">
              <div class="dash-widget dash-widget5">
                <div class="dash-widget-info text-left d-inline-block">
                  <span>Fondateur </span>
                  <h3>{{ objet.nb_fondator }}</h3>
                </div>
                <span class="float-left"
                  ><img
                    src="template_admin/img/dash/dash-3.png"
                    alt=""
                    width="80"
                /></span>
              </div>
            </router-link>
          </div>
          <div class="col-md-6 col-sm-6 col-lg-6 col-xl-4">
            <router-link to="/admin-liste-etablissements">
              <div class="dash-widget dash-widget5">
                <span class="float-right"
                  ><img
                    src="template_admin/img/dash/dash-2.png"
                    width="80"
                    alt=""
                /></span>
                <div class="dash-widget-info text-right">
                  <span>Ecoles</span>
                  <h3>{{ objet.nb_ecole }}</h3>
                </div>
              </div>
            </router-link>
          </div>
          <!-- <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div class="dash-widget dash-widget5">
              <div class="dash-widget-info d-inline-block text-left">
                <span>Total Earnings</span>
                <h3>$20,000</h3>
              </div>
              <span class="float-right"
                ><img
                  src="template_admin/img/dash/dash-4.png"
                  alt=""
                  width="80"
              /></span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../utils/Variable";

import BaseAdmin from "../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },
  data() {
    return {
      is_admin: false,
      is_moderator: false,
      is_supervisor: false,
      objet: {
        nb_adjoint: 0,
        nb_ecole: 0,
        nb_fondator: 0,
      },
      erreur_de_chargement: false,
    };
  },
  mounted() {
    this.setAutorisation();
    this.getStat();
  },
  methods: {
    hideErrorMessage: function () {
      this.echec_connexion = false;
      this.erreur_de_chargement = false;
    },
    setAutorisation() {
      this.is_admin = API_URL.is_admin();
      this.is_moderator = API_URL.is_moderator();
      this.is_supervisor = API_URL.is_supervisor();
    },
    getStat: function () {
      document.getElementById("loading_circle_1").style.display =
        "inline-block";
      this.erreur_de_chargement = false;
      axios
        .get(API_URL.DASHBOARD)
        .then((response) => {
          console.log(response);
          this.objet = response.data.data.stats;
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          alert("Prbleme de connexion, une erreur est survenue");
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },
  },
};
</script>
