<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="PROFESSOR" />
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-md-6">
              <h3 class="page-title mb-0 text-left">
                {{objet.school.name}}
                <br/>
                [{{objet.name}} - {{nom_matiere}} ] 
                <br/>
                ( {{year_period_name}} )
                
              </h3>
            </div>
            <div class="col-md-6">
              <ul class="breadcrumb mb-0 p-0 float-right">
                <li class="breadcrumb-item">
                  <router-link to="/prof-dashboard"><i class="fas fa-home"></i> Accueil</router-link>
                </li>
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-home"></i> Etablissement</a>
                </li>
                <li class="breadcrumb-item"><span>liste eleves</span></li>
              </ul>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-sm-12 text-left">
                    <a v-if="objet.id != undefined"
                        @click="$router.go(-1)"
                        class=" float-left"
                        >
                          &lt; Retour
                    </a>
                    <br/><br/>
                    <div class="page-title">
                     
                      <h4> Absences des eleves de {{objet.name}} en {{nom_matiere}} </h4>


                      <div class="loader" id="loading_circle_1"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table custom-table">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-left" >Nom et prenoms </th>
                        <th class="text-left" >Absences </th>
                        <th class="text-left" ># </th>
                      </tr>
                    </thead>
                    <tbody>
                      
                      <!-- <tr v-for="item_student_absence in students_absences" :key="item_student_absence.id" class="text-center" >
                        <td>
                          <h2>
                            {{item_student_absence.student.first_name}}
                            {{item_student_absence.student.last_name}}
                          </h2>
                        </td>
                        <td>
                          <h2>
                            {{item_student_absence.missed_hour}} H manquées <br/>
                            {{item_student_absence.start_hour}}
                          </h2>
                        </td>
                      </tr> -->

                      <tr v-for="item_absence_key in all_absence_key" :key="item_absence_key.id" class="text-center" >
                        <td class="text-left">
                            {{item_absence_key.eleve}}
                        </td>
                        <td class="text-left">
                            {{item_absence_key.nb_absence}} H
                        </td>
                        <td class="text-left">
                            <button 
                              class="btn btn-secondary"
                              data-toggle="modal" 
                              v-bind:data-target="'#historique_absence_'+item_absence_key.id" 
                              > Details </button>

                            <div class="modal custom-modal fade" v-bind:id="'historique_absence_'+item_absence_key.id">
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h4 class="modal-title">Historique Absence {{item_absence_key.eleve}} en {{nom_matiere}} </h4>
                                  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                </div>
                                <div class="modal-body">
                                  <table class="table text-left" >
                                    <thead>
                                      <tr>
                                        <th>Date</th>
                                        <th>Heure</th>
                                        <th>Duree</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="item_details_absence in item_absence_key.details" :key="item_details_absence.id" >
                                        <td class="text-left"> {{item_details_absence.date}} </td>
                                        <td class="text-left"> {{item_details_absence.start_hour}} </td>
                                        <td class="text-left"> {{item_details_absence.missed_hour}}H </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div> 
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../utils/Variable";
// import setAuthHeader from '../utils/setAuthHeader';

import BaseAdmin from "../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },
  data() {
    return {
      
      id : this.$route.params.id_classe,
      nom_period : this.$route.params.nom_period,
      year_period_id : this.$route.params.id_period,
      year_period_name : "",
      nom_matiere : this.$route.params.nom_matiere,
      subject_id : this.$route.params.subject_id,


      echec_connexion: false,
      erreur_de_chargement: false,
      objet: {
        school : {}
      },
      requete_reussi:false,
      message_erreur:"",
	    students:[],
	    students_absences:[],
	    all_absence_key:[],
    };
  },
  mounted() {
    this.getItemData();
    this.getAbsence();
    this.getDetailsYearPeriod();
  },
  methods: {
    hideErrorMessage: function () {
      this.echec_connexion = false;
      this.erreur_de_chargement = false;
    },

     getItemData() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.CLASSROOM_STUDENT_LIST + "/" + this.id;
      axios
        .get(route)
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.objet = response.data.data.classroom;
            this.students = response.data.data.students;
            console.log(response.data.school);
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

    getAbsence(){
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.CONSULTER_ABSENCE;
      axios
        .post(route,{
          subject_id : this.subject_id,
          classroom_id : this.id,
          year_period_id : this.year_period_id,
        })
        .then((response) => {
          console.log(response);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.students_absences = response.data.data;

            var all_absence = [];
            for(var i=0;i<this.students_absences.length;i++){
              
              if( all_absence[ this.students_absences[i].student.last_name +' '+ this.students_absences[i].student.first_name ] == undefined )
              {
                all_absence[ this.students_absences[i].student.last_name +' '+ this.students_absences[i].student.first_name ] = 0;
              }

              all_absence[ this.students_absences[i].student.last_name +' '+ this.students_absences[i].student.first_name ] += parseInt(this.students_absences[i].missed_hour);

            }

          console.log(all_absence);

          var tableau_absences = [];
          var increment= 0;
          Object.keys(all_absence).map((key) => {
              var item = [];
              var details = [];


              item['id'] = increment;
              item['eleve'] = key;
              item['nb_absence'] = all_absence[key];

            var id_details = 0;
            for(var j=0;j<this.students_absences.length;j++){
              
              var item_details = [];
              if( key == this.students_absences[j].student.last_name +' '+ this.students_absences[j].student.first_name )
              {
                item_details ['id'] = j ;
                item_details ['start_hour'] = this.students_absences[j].start_hour ;
                item_details ['missed_hour'] = this.students_absences[j].missed_hour ;
                item_details ['date'] = this.students_absences[j].created_at.substring(0,10) ;
                
                details[id_details] = item_details ;
                id_details++
              }
            }

            item['details'] = details;
            
            tableau_absences[increment] = item;
            increment++;

          });

            this.all_absence_key = tableau_absences;

            console.log(this.all_absence_key);

            console.log(response);
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

     getDetailsYearPeriod() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.GET_YEAR_PERIOD + "/" + this.year_period_id;
      axios
        .get(route)
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.year_period_name = response.data.data.name;
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

  },
};
</script>
