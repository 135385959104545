<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="PARENT" />
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-md-6">
              <h3 class="page-title mb-0 text-left">
                {{ objet.last_name }}
                <br />
                {{ objet.first_name }}
                <!-- {{objet.student_classroom.classroom.name}}  -->
                <br />
                ( {{ year_period_name }} )
              </h3>
            </div>
            <div class="col-md-6">
              <ul class="breadcrumb mb-0 p-0 float-right">
                <li class="breadcrumb-item">
                  <router-link to="/parent-dashboard"
                    ><i class="fas fa-home"></i> Accueil</router-link
                  >
                </li>
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-home"></i> Etablissement</a>
                </li>
                <li class="breadcrumb-item"><span>liste eleves</span></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-sm-12 text-left">
                    <router-link
                      to="/parent-dashboard"
                      style="cursor: pointer"
                      class="float-left"
                    >
                      &lt; Retour
                    </router-link>
                    <br /><br />
                    <div class="page-title">
                      <h4>
                        Bulletin :
                        <b> {{ objet.last_name }} {{ objet.first_name }} </b>
                      </h4>
                      <div class="row m-1">
                        <label> Choisir le trimestre </label>
                        <select
                          class="form-control"
                          v-model="year_period_id"
                          @change="refresh"
                        >
                          <option
                            v-for="item_period in objet.student_classroom
                              .classroom.year_period"
                            :key="item_period.id"
                            v-bind:value="item_period.id"
                          >
                            {{ item_period.name }}
                          </option>
                        </select>
                      </div>

                      <div class="row">
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          class="btn btn-success"
                          data-toggle="modal"
                          data-target="#modal_historique_note"
                        >
                          Historique des notes
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-toggle="modal"
                          data-target="#modal_historique_absence"
                        >
                          Historique des absences
                        </button>
                      </div>
                      <div class="loader" id="loading_circle_1"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table custom-table">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-left">Matieres</th>
                        <th class="text-left">Moyennes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item_moyenne in moyennes"
                        :key="item_moyenne.id"
                        class="text-center"
                      >
                        <td>
                          <h2>
                            {{ item_moyenne.matiere }}
                          </h2>
                        </td>
                        <td>
                          <h2>{{ item_moyenne.moyenne }}/20</h2>
                        </td>
                        <!-- Historique note -->
                        <div
                          class="modal custom-modal fade"
                          id="modal_historique_note"
                        >
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h4 class="modal-title">Details Moyenne</h4>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  &times;
                                </button>
                              </div>
                              <div class="modal-body">
                                <table class="table">
                                  <thead>
                                    <th>Matiere</th>
                                    <th>Date</th>
                                    <th>Note</th>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item_note in item_moyenne.historique_note"
                                      :key="item_note.id"
                                    >
                                      <td>{{ item_note.subject_name }}</td>
                                      <td>
                                        {{
                                          item_note.created_at.substring(0, 10)
                                        }}
                                      </td>
                                      <td>
                                        {{ item_note.note }} /
                                        {{ item_note.note_on }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Historique absence -->
    <div class="modal custom-modal fade" id="modal_historique_absence">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Historique Absence</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div class="modal-body">
            <table class="table">
              <thead>
                <th><small> Date </small></th>
                <th><small> Matiere </small></th>
                <th><small> Heure de cours </small></th>
                <th>
                  <small>
                    Nombre <br />
                    Heure Manquee
                  </small>
                </th>
              </thead>
              <tbody>
                <tr
                  v-for="item_absence in historique_absence"
                  :key="item_absence.id"
                >
                  <td>{{ item_absence.created_at.substring(0, 10) }}</td>
                  <td>
                    {{ item_absence.classroom_subject_professor.subject.name }}
                  </td>
                  <td>{{ item_absence.start_hour }}</td>
                  <td>{{ item_absence.missed_hour }} H</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../utils/Variable";
// import setAuthHeader from '../utils/setAuthHeader';

import BaseAdmin from "../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },
  data() {
    return {
      id_eleve: this.$route.params.id_eleve,
      id_classe: this.$route.params.id_classe,
      // year_period_id : this.$route.params.id_period,
      year_period_id: null,

      year_period_name: "",
      historique_absence: [],

      echec_connexion: false,
      erreur_de_chargement: false,

      objet: {
        student_classroom: {
          classroom: {
            year_period: [],
          },
        },
      },

      moyennes: [],
      requete_reussi: false,
      message_erreur: "",
    };
  },
  mounted() {
    this.getItemData();
    // this.getDetailsYearPeriod();
    // this.getBulletin();
    // this.getAbsenceHistory();
  },
  methods: {
    hideErrorMessage: function () {
      this.echec_connexion = false;
      this.erreur_de_chargement = false;
    },

    getItemData() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route =
        API_URL.READ_UTILISATEUR + "/" + this.id_eleve + "?with-classroom=1";
      axios
        .get(route)
        .then((response) => {
          console.log(response);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.objet = response.data.data.user;
            this.year_period_id =
              this.objet.student_classroom.classroom.year_period[0].id;
            // alert(this.year_period_id);

            this.getDetailsYearPeriod();
            this.getBulletin();
            this.getAbsenceHistory();
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

    refresh() {
      this.getBulletin();
      this.getAbsenceHistory();
      this.getDetailsYearPeriod();
    },

    getBulletin() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.BULLETIN_ELEVE;
      axios
        .post(route, {
          student_id: this.id_eleve,
          classroom_id: this.id_classe,
          year_period_id: this.year_period_id,
        })
        .then((response) => {
          console.log(response);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.moyennes = response.data.data.bulletin;
            //  var the_array =  response.data.data;
            // var liste_matieres =  Object.keys(the_array[0]);
            // var liste_moyennes =  Object.values(the_array[0]);

            // var final_average_array = [];
            // for (let index = 0; index < liste_matieres.length; index++){
            //   final_average_array[index] = {
            //     'id' : index,
            //     'matiere' : liste_matieres[index],
            //     'moyenne' : liste_moyennes[index],
            //   }
            // }

            // this.moyennes = final_average_array;

            console.log(this.moyennes);
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

    getAbsenceHistory() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.HISTORIQUE_ABSENCE;
      axios
        .post(route, {
          student_id: this.id_eleve,
          classroom_id: this.id_classe,
          year_period_id: this.year_period_id,
        })
        .then((response) => {
          console.log(response);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.historique_absence = response.data.data;
            console.log(this.historique_absence);
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

    getDetailsYearPeriod() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.GET_YEAR_PERIOD + "/" + this.year_period_id;
      axios
        .get(route)
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.year_period_name = response.data.data.name;
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },
  },
};
</script>
