<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="PROFESSOR" />
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-md-6">
              <h3 class="page-title mb-0 text-left">
                {{objet.school.name}}
                <br/>
                [{{objet.name}} - {{nom_matiere}} ] 
              </h3>
            </div>
            <div class="col-md-6">
              <ul class="breadcrumb mb-0 p-0 float-right">
                <li class="breadcrumb-item">
                  <router-link to="/prof-dashboard"><i class="fas fa-home"></i> Accueil</router-link>
                </li>
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-home"></i> Etablissement</a>
                </li>
                <li class="breadcrumb-item"><span>liste eleves</span></li>
              </ul>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-sm-12 text-left">
                    <router-link v-if="objet.id != undefined"
                        to="/prof-dashboard"
                        class=" float-left"
                        >
                          &lt; Retour
                        </router-link
                      >
                    <br/><br/>
                    <div class="page-title">
                     
                     <div class="modal custom-modal fade" id="choose_period">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title"> 
                          Choisissez la periode
                          <br/>
                         {{objet.name}} {{nom_matiere}}
                      </h4>
                      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>
                    <div class="modal-body">
                      <select class="form-control" v-model="year_period_id">
                        <option 
                          v-for="item_period in objet.year_period" :key="item_period.id"
                          v-bind:value="item_period.id" 
                          > {{item_period.name}} </option>
                      </select>
                    </div>
                    <div class="modal-footer">
                      <h3>
                        <button 
                            class="btn btn-success" 
                            @click="goToSection" 
                             data-dismiss="modal" aria-hidden="true"
                          > Continuer </button>
                      </h3>
                    </div>
                  </div>
                </div>
              </div> 

                        <!-- v-bind:to="'/professor-enregistrer-notes/'+objet.id+'/'+nom_matiere+'/'+subject_id+'/'+year_period_id" -->

                      <button 
                        class="btn btn-primary float-left mr-2"
                        @click="section_choisi='add_note'"
                        data-toggle="modal" 
                        data-target="#choose_period"
                        >
                         Enregistrer des Notes 
                      </button>

                      <button 
                        class="btn btn-secondary float-left mr-2"
                        @click="section_choisi='add_absences'"
                        data-toggle="modal" 
                        data-target="#choose_period"
                        >
                         Enregistrer des Absences 
                      </button>
                      
                      <button 
                        class="btn btn-primary float-left mr-2"
                        @click="section_choisi='consulter_moyenne'"
                        data-toggle="modal" 
                        data-target="#choose_period"
                        >
                         Consulter les moyennes 
                      </button>
                      
                      <button 
                        class="btn btn-secondary float-left mr-2"
                        @click="section_choisi='bilan_absence'"
                        data-toggle="modal" 
                        data-target="#choose_period"
                        >
                         Consulter les Absences
                      </button>
                   <!--    <router-link 
                        v-bind:to="'/professor-enregistrer-absences/'+objet.id+'/'+nom_matiere+'/'+subject_id+'/'+year_period_id"
                        class="btn btn-secondary float-left mr-2"
                        >
                         Enregistrer des absences
                      </router-link>

                      <router-link 
                        v-bind:to="'/professor-consulter-moyennes/'+objet.id+'/'+nom_matiere+'/'+subject_id+'/'+year_period_id"
                        class="btn btn-primary float-left ml-2"
                        >
                         Consulter les moyennes 
                      </router-link>

    
                      <router-link 
                        v-bind:to="'/professor-consulter-absences/'+objet.id+'/'+nom_matiere+'/'+subject_id+'/'+year_period_id"
                        class="btn btn-secondary float-left ml-2"
                        >
                         Consulter les Absences
                      </router-link> -->

                      <div class="loader" id="loading_circle_1"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table custom-table">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-left" >Nom et prenoms </th>
                        <th class="text-left" >Telephone </th>
                        <th>Parent <br/> Telephone parent</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item_student in students" :key="item_student.id" class="text-center" >
                        <td>
                          <h2>
                            {{item_student.first_name}} {{item_student.last_name}}
                          </h2>
                        </td>
                        <td>
                          <h2>
                            {{item_student.prefix_mobile}} {{item_student.mobile}}
                          </h2>
                        </td>
                        <td>
                            {{item_student.parent.last_name}} {{item_student.parent.first_name}}
                            <br/>
                            {{item_student.parent.prefix_mobile}} {{item_student.parent.mobile}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    

  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../utils/Variable";
// import setAuthHeader from '../utils/setAuthHeader';

import BaseAdmin from "../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },
  data() {
    return {
      id : this.$route.params.id_classe,
      year_period_name : this.$route.params.nom_period,
      section_choisi : "",
      year_period_id : null,
      subject_id : this.$route.params.subject_id,
      nom_matiere : this.$route.params.nom_matiere,
      new_paiement_amount: null,
      echec_connexion: false,
      erreur_de_chargement: false,
      objet: {
        school : {}
      },
      requete_reussi:false,
      message_erreur:"",
	    students:[],
	    year_period_detail:[],
    };
  },
  mounted() {
    this.getItemData();
    // this.getDetailsYearPeriod();
  },
  methods: {
    hideErrorMessage: function () {
      this.echec_connexion = false;
      this.erreur_de_chargement = false;
    },

     getItemData() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.CLASSROOM_STUDENT_LIST + "/" + this.id;
      axios
        .get(route)
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.objet = response.data.data.classroom;
            this.students = response.data.data.students;
            console.log(response.data.school);
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

     getDetailsYearPeriod() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.GET_YEAR_PERIOD + "/" + this.year_period_id;
      axios
        .get(route)
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            // alert(response.data.message);
            this.year_period_name = response.data.data.name;
            // alert(this.year_period_name)
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

    goToSection(){
      //section_choisi
      var racine_destination = null;
      switch(this.section_choisi){
        case 'add_note' : racine_destination = "/professor-enregistrer-notes/" ;break;
        case 'consulter_moyenne' : racine_destination = "/professor-consulter-moyennes/" ;break;
        case 'add_absences' : racine_destination = "/professor-enregistrer-absences/" ;break;
        case 'bilan_absence' : racine_destination = "/professor-consulter-absences/" ;break;
      }


      if(racine_destination == null || this.year_period_id == null ){
        alert("section ou periode invalide");
        return false;
      }

      this.$router.replace(
            racine_destination
                 +this.objet.id
                +"/"+this.nom_matiere
                +"/"+this.subject_id
                +"/"+this.year_period_id
            );
    }

  },
};
</script>
