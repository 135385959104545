// const URL_DE_BASE = "http://192.168.43.247:8000";
// const URL_DE_BASE = "http://192.168.43.188:8000";
// const URL_DE_BASE = "http://192.168.1.10:8000";

// const URL_DE_BASE = "https://www.lomeko.straton-system.com";
const URL_DE_BASE = "https://school.lomeko.com";
// const URL_DE_BASE = "http://localhost:8000";
const URL_DE_BASE_API = URL_DE_BASE + "/api";

const USER_PREFIX = URL_DE_BASE_API + "/users";
const SCHOOL_PREFIX = URL_DE_BASE_API + "/schools";

const API_URL = {
  LOGIN: URL_DE_BASE_API + "/login",

  DASHBOARD: USER_PREFIX + "/dashboard",
  LISTE_FONDATEUR: USER_PREFIX + "?type=FONDATOR",
  LISTE_ADJOINTS: USER_PREFIX + "?type[0]=MODERATOR&type[1]=SUPERVISOR",
  LISTE_SUPERVISOR: USER_PREFIX + "?type[0]=SUPERVISOR",

  UTILISATEUR: USER_PREFIX,
  READ_UTILISATEUR: USER_PREFIX + "/read",
  UPDATE_UTILISATEUR: USER_PREFIX + "/update",
  EFFACER_UTILISATEUR: USER_PREFIX + "/delete",

  // -------------------------------------
  ALL_SCHOOL: SCHOOL_PREFIX + "?with-fondator=1",
  SCHOOL_PROFESOR: SCHOOL_PREFIX,
  FONDATOR_SCHOOL: SCHOOL_PREFIX + "?with-fondator=1&with-classroom=1",
  ENREGISTRER_SCHOOL: SCHOOL_PREFIX,
  ADMIN_READ_FONDATOR_SCHOOL: USER_PREFIX + "/schools",
  READ_SCHOOL: SCHOOL_PREFIX,
  EFFACER_ETABLISSEMENT: SCHOOL_PREFIX,
  UPDATE_CLASSROOM: SCHOOL_PREFIX,

  CLASSROOM_PROFESSOR_SUBJECT: URL_DE_BASE_API + "/classrooms/read",
  CLASSROOM_STUDENT_LIST: URL_DE_BASE_API + "/classrooms/students",
  STUDENT_NEW_PAIEMENT: URL_DE_BASE_API + "/tuition/add-payment",

  ALL_SUBJECT: URL_DE_BASE_API + "/subjects/all",
  ATTACH_SUBJECT_TO_CLASSROOM:
    URL_DE_BASE_API + "/subjects/attach-to-classroom/multiple",
  EFFACER_LIEN_PROF_MATIERE:
    URL_DE_BASE_API + "/subjects/lien-subject-prof/delete",

  // --------------PROFESSOR--------------------
  ALL_PROF_CLASSROOM: URL_DE_BASE_API + "/professor/get-classroom-list",
  ENREGISTRER_NOTES: URL_DE_BASE_API + "/classrooms/student/notes/add",
  CONSULTER_MOYENNE:
    URL_DE_BASE_API + "/professor/get-each-student-average-in-subject",
  ENREGISTRER_ABSENCES: URL_DE_BASE_API + "/absences/registration",
  CONSULTER_ABSENCE: URL_DE_BASE_API + "/absences/student-in-subject",

  GET_YEAR_PERIOD: URL_DE_BASE_API + "/schools/year-period",

  // --------------PARENT--------------------
  ALL_PARENT_CHILD: URL_DE_BASE_API + "/tuition/parent",
  BULLETIN_ELEVE:
    URL_DE_BASE_API + "/classrooms/student/average?with-note-history=1",
  HISTORIQUE_ABSENCE: URL_DE_BASE_API + "/absences/student/resume",

  // ----------------------------------
  is_admin: function () {
    const connected_user_type = localStorage.getItem("user_type");
    if (connected_user_type == "ADMIN") {
      return true;
    }
    return false;
  },

  is_moderator: function () {
    const connected_user_type = localStorage.getItem("user_type");
    if (connected_user_type == "MODERATOR") {
      return true;
    }
    return false;
  },

  is_supervisor: function () {
    const connected_user_type = localStorage.getItem("user_type");
    if (connected_user_type == "SUPERVISOR") {
      return true;
    }
    return false;
  },

  is_fondator: function () {
    const connected_user_type = localStorage.getItem("user_type");
    if (connected_user_type == "FONDATOR") {
      return true;
    }
    return false;
  },

  is_profesor: function () {
    const connected_user_type = localStorage.getItem("user_type");
    if (connected_user_type == "PROFESOR") {
      return true;
    }
    return false;
  },

  is_parent: function () {
    const connected_user_type = localStorage.getItem("user_type");
    if (connected_user_type == "PARENT") {
      return true;
    }
    return false;
  },
};

export default API_URL;
