<template>
  <section>

<!-- Mirrored from templatemo.com/templates/templatemo_540_lava_landing_page/index.html by HTTrack Website Copier/3.x [XR&CO'2014], Sun, 06 Mar 2022 10:00:42 GMT -->
<head>

    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="">
    <meta name="author" content="TemplateMo">
    <link href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&amp;display=swap" rel="stylesheet">

    <title>Lomeko School</title>
<!--

Lava Landing Page

https://templatemo.com/tm-540-lava-landing-page

-->

    <!-- Additional CSS Files -->
    <link rel="stylesheet" type="text/css" href="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/css/bootstrap.min.css">

    <link rel="stylesheet" type="text/css" href="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/css/font-awesome.css">

    <link rel="stylesheet" href="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/css/templatemo-lava.css">

    <link rel="stylesheet" href="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/css/owl-carousel.css">
</head>

<body>

    <!-- ***** Preloader Start ***** -->
    <!-- <div id="preloader">
        <div class="jumper">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div> -->
    <!-- ***** Preloader End ***** -->


    <!-- ***** Welcome Area Start ***** -->
    <div class="welcome-area" id="welcome" 
        style="background-image:url('/landing/img/bg-lomeko.jpeg') !important;
               background-repeat:no-repeat;
               background-size:cover;
               "
            >

        <!-- ***** Header Text Start ***** -->
        <div class="header-text" style="background:#fff">
            <div class="container text-center" id="content-text">
                <br/>
                <h1 style="margin:5px" > LOMEKO SCHOOL </h1>
                <br/>
                <h4 style="margin:5px"> Application de suivi scolaire. </h4>
                <br/>
                <a href="#about-section" class="btn btn-info" > Decouvrir </a> &nbsp;
                
                <router-link to="/connexion" class="btn btn-warning">Se connecter</router-link>
                <!-- <div class="row">
                    <div class="left-text col-lg-6 col-md-12 col-sm-12 col-xs-12"
                        data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                        <h1>Simple App that we <em>CREATE</em></h1>
                        <p>Lava <a href="#">HTML landing page</a> template is provided by <a href="#">TemplateMo</a>.
                           You can modify and use it for your commercial websites for free of charge. This template is last updated on 29 Oct 2019.</p> 
                        <a href="#about" class="main-button-slider">KNOW US BETTER</a>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- ***** Header Text End ***** -->
    </div>
    <!-- ***** Welcome Area End ***** -->

    <!-- ***** Features Big Item Start ***** -->
    <section class="section" id="about-section">
        <div class="container pt-5">
            <div class="row">
                
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                    data-scroll-reveal="enter bottom move 30px over 0.6s after 0.4s">
                    <div class="features-item">
                        <div class="features-icon">
                            <h2>01</h2>
                            <!-- <img src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/images/features-icon-2.png" alt=""> -->
                            <br/><br/>
                            <h4>Vos eleves seront assidus au cours </h4>
                            <p>Cette application permettra aux professeurs d'envoyer en temps réel des notifications d'absence aux parents.</p>
                            <!-- <a href="#testimonials" class="main-button">
                                Discover More
                            </a> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                    data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                    <div class="features-item">
                        <div class="features-icon">
                            <h2>02</h2>
                            <!-- <img src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/images/features-icon-1.png" alt=""> -->
                            <br/><br/>
                            <h4>A qui s'adresse l'application</h4>
                            <p>
                                Cette application a été conçue pour tout type d'etablissement.<br/>
                                Elle donne accès aux Fondateurs, educateurs, professeurs et 
                                parents d'élèves au suivi en temps réel du rendement scolaire des élèves.
                            </p>
                            <!-- <a href="#testimonials" class="main-button">
                                Read More
                            </a> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                    data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
                    <div class="features-item">
                        <div class="features-icon">
                            <h2>03</h2>
                            <!-- <img src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/images/features-icon-3.png" alt=""> -->
                            <br/><br/>
                            <h4>Toutes vos notes en un seul endroit</h4>
                            <p>
                                Grace à school transcipt, les enseignants pourront enregistrer systematiquement les notes des élèves et les envoyer immediatement 
                                aux parents afin de leur permettre de connaitre le rendement scolaire de leurs enfants.
                            </p>
                            <!-- <a href="#testimonials" class="main-button">
                                More Detail
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ***** Features Big Item End ***** -->

    <div class="left-image-decor"></div>

    <!-- ***** Features Big Item Start ***** -->
    <section class="section" id="promotion">
        <div class="container">
            <div class="row">
                <div class="left-image col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix-big"
                    data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                    <img src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/images/left-image.png" class="rounded img-fluid d-block mx-auto" alt="App">
                </div>
                <div class="right-text offset-lg-1 col-lg-6 col-md-12 col-sm-12 mobile-bottom-fix">
                    <ul>
                        <li data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
                            <img src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/images/about-icon-01.png" alt="">
                            <div class="text">
                                <h4>Vue d'ensemble sur votre etablissement</h4>
                                <p>Garder un oeil sur les notes et les absences, suivre le paiement de la scolarite de votre etablissement grâce à l'application web admin lomeko school transcript</p>
                            </div>
                        </li>
                        <li data-scroll-reveal="enter right move 30px over 0.6s after 0.5s">
                            <img src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/images/about-icon-02.png" alt="">
                            <div class="text">
                                <h4>5 eleves sur 45 sont regulierement absent en classe</h4>
                                <p>Les professeurs feront l'appel et cocheront les absences via l'application et le parent recevra une notification instantannée de 
                                    l'absence de son enfant. Cette mesure permettra au parent d'être informé de la mobilité de son enfant.</p>
                            </div>
                        </li>
                        <li data-scroll-reveal="enter right move 30px over 0.6s after 0.6s">
                            <img src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/images/about-icon-03.png" alt="">
                            <div class="text">
                                <h4>Les eleves ne montrent que les bonnes notes</h4>
                                <p>Les parents envoient directement entemps reel les notes aux parents aui les verront systematiquement et prendront  les mesures
                                    adequates pour la reussite de leurs enfants.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <!-- ***** Features Big Item End ***** -->

    <div class="right-image-decor"></div>


    <!-- ***** Testimonials Starts ***** -->
    <section class="section" id="testimonials">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <div class="center-heading">
                        <h2>Ce que les gens pensent <em>de nous</em></h2>
                        <p>Les retours d'experience de nos premiers utilisateurs.</p>
                    </div>
                </div>
                <div class="col-lg-10 col-md-12 col-sm-12 mobile-bottom-fix-big"
                    data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                    <!-- <div class="owl-carousel owl-theme"> -->
                    <div>
                        
                        <div class="item service-item">
                            <div class="author">
                                <i><img src="/landing/img/testimonial2.png" alt="Second Author"></i>
                            </div>
                            <div class="testimonial-content">
                                <h4>M.Kouassi</h4>
                                <span>Fondateur d'ecole</span>
                                <p>“Lomeko school a changé l'environnement de mon etablissement! J'en fais plus en un jour qu jamais auparavant.”</p>
                            </div>
                        </div>

                        <div class="item service-item">
                            <div class="author">
                                <i><img src="/landing/img/testimonial2.png" alt="Second Author"></i>
                            </div>
                            <div class="testimonial-content">
                                <h4>M.Sylla</h4>
                                <span>Professeur</span>
                                <p>“
                                    J'ai un oeil que les notes et absences de mes eleves.
                                    La liste de classes numerique et l'enregistrement automatique des notes me rendent la tache facile.
                                ”</p>
                            </div>
                        </div>

                        <div class="item service-item">
                            <div class="author">
                                <i><img src="/landing/img/testimonial2.png" alt="Second Author"></i>
                            </div>
                            <div class="testimonial-content">
                                <h4>M.Axel</h4>
                                <span>Parent d'élève</span>

                                <p>“J'ai toujours eu du mal à suivre mon enfant. J'ai essayé l'application et grâce 
                                    aux notifications d'absence que je reçois, mon fils ne sèche plus les cours.”</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ***** Testimonials Ends ***** -->


    <!-- ***** Footer Start ***** -->
    <footer id="contact-us" style="margin-top:10px !important">
        <div class="container">
            <div class="footer-content" style="padding-bottom:0px !important" >
                
                <div class="row">
                    
                    <section class="section" id="testimonials">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 text-center">
                                    <div class="item service-item">
                                        
                                        <div class="testimonial-content white-testimonial-content " style="width:100% !important">
                                            <h4>Application de base</h4>
                                            <span>Parent d'élève</span>

                                            <p> L'etablissement envoie des notifications
                                                <br/> via internet aux parents
                                                 d'élèves
                                                 <br/> qui doivent avoir un smartphone 
                                                 pour se connecter a l'application </p>

                                            <br/>
                                            <p><b>150.000 FCFA/an</b></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="item service-item">
                                        
                                        <div class="testimonial-content white-testimonial-content" style="width:100% !important">
                                            <h4>Application Avancé</h4>
                                            <span>Parent d'élève</span>

                                            <p> L'etablissement envoie des notifications 
                                                <br/>
                                                via internet aux parents d'élèves 
                                                <br/>
                                                qui les reçoivent par SMS
                                                <br/>
                                                 dans leur messagerie </p>

                                                <br/>
                                            <p><b>+2 FCFA/SMS ENVOYE</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                <div class="row">
                    <!-- ***** Contact Form Start ***** -->
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <img src="/landing/img/contact.png" width="100%" />
                    </div>
                    <!-- ***** Contact Form End ***** -->
                    <div class="right-content col-lg-6 col-md-12 col-sm-12">
                        <h2>Contactez Nous</h2>
                        <p>Contactez notre equipe qui vous  fournira plus de details.</p>
                            
                        <div>
                            <br/>
                            <p> contact@lomeko.com </p>
                            <br/>
                            <p> 25 23 00 12 34 </p>
                            <p> 05 06 13 55 67 </p>
                            <p> 07 77 74 77 65 </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="sub-footer">
                        <p>Copyright &copy; 2022 Lomeko School

                        | Tout droits réservés  </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>

  
</body>

  </section>
  <!-- ***** Testimonials Ends ***** -->
</template>

  <!-- jQuery -->
    <script src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/js/jquery-2.1.0.min.js"></script>

    <!-- Bootstrap -->
    <script src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/js/popper.js"></script>
    <script src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/js/bootstrap.min.js"></script>

    <!-- Plugins -->
    <script src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/js/owl-carousel.js"></script>
    <script src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/js/scrollreveal.min.js"></script>
    <script src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/js/waypoints.min.js"></script>
    <script src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/js/jquery.counterup.min.js"></script>
    <script src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/js/imgfix.min.js"></script>

    <!-- Global Init -->
    <script src="https://templatemo.com/templates/templatemo_540_lava_landing_page/assets/js/custom.js"></script>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    
    .welcome-area{
        position: relative !important;
    }

    .header-text{
            width: 100% !important;
            height: 280px;
            position: absolute;
            margin: 0px !important;
            top: 47%;
            transform: translateY(-47%);
            background-color: rgba(255,255,255,0.4) !important;
    }
    #content-text{
        width: 80% !important;
        height: 310px;
        padding: 5px;
        background-color: rgba(255,255,255,0.9) !important;
        margin-top: -14px;
        position: none !important;
    }

     .white-testimonial-content{
        background:#fff !important;
    }
    .white-testimonial-content *{
        color: #000 !important;
    }

    
    @media only screen and (max-width: 768px) {
        .header-text{
            top: 0;
            transform: 0;
        }
        #content-text{
            height: 360px !important;
        }
        
    }

</style>