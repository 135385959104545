<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="FONDATOR" />

    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
              <h5 class="text-uppercase mb-0 mt-0 page-title text-left">
                Vue Pivot
              </h5>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
              <ul class="breadcrumb float-right p-0 mb-0">
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-home"></i> Acceuil</a>
                </li>
                <li class="breadcrumb-item">
                  <span>Gerer les Classes et les Frais de Scolarité</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header text-left">
                <h3>Gerer les Classes et les Frais de Scolarité</h3>
                <router-link to="/fondator-dashboard">
                  &lt; Retour au tableau de bord
                </router-link>
              </div>
              <div class="loader" id="loading_circle_1"></div>
              <div class="card-body">
                <!-- Go to Gestion Prof -->
                <div class="row">
                  <div class="offset-md-3 col-md-4">
                    <h4 class="text-left">Choisissez l'Etablissement</h4>
                    <br />
                    <select v-model="id_school_choisi" class="form-control">
                      <option
                        v-for="item_school in schools"
                        :key="item_school.id"
                        v-bind:value="item_school.id"
                      >
                        {{ item_school.name }}
                      </option>
                    </select>
                    <br />
                    <h3 class="text text-right">
                      <button class="btn btn-success" @click="goToClasseList">
                        Continuer
                      </button>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ///modal reponse// -->
    <button
      id="btn_modal"
      type="button"
      style="display: none"
      data-toggle="modal"
      data-target="#add_new_event"
      class="btn btn-primary btn-block"
    >
      Modal Reponse
    </button>

    <div class="modal custom-modal fade" id="add_new_event">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Reponse</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-success" v-if="requete_reussi == true">
              <h4>{{ msg_success }}</h4>
            </div>

            <div v-if="requete_reussi == false">
              <div class="alert alert-danger">
                <h4>Les champs suivants sont invalides :</h4>
                <ul>
                  <li v-for="msg in message_erreur" :key="msg">
                    {{ msg }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../../utils/Variable";
import BaseAdmin from "../../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },

  data() {
    return {
      id_school_choisi: 0,
      schools: [],

      msg_success: "",
      erreur_de_chargement: false,
      requete_reussi: false,
      message_erreur: [],
    };
  },
  mounted() {
    this.getSchools();
  },
  methods: {
    getSchools: function () {
      document.getElementById("loading_circle_1").style.display =
        "inline-block";
      this.erreur_de_chargement = false;
      axios
        .get(API_URL.FONDATOR_SCHOOL)
        .then((response) => {
          console.log(response);
          this.schools = response.data.data.schools;
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          alert("Prbleme de connexion, une erreur est survenue");
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

    goToClasseList() {
      if (
        this.id_school_choisi == 0 ||
        this.id_school_choisi == "" ||
        this.id_school_choisi == null
      ) {
        alert("Veuillez remplir correctement les champs requis");
        return false;
      } else {
        this.$router.replace("/fondator-frais-classe/" + this.id_school_choisi);
      }
    },
  },
};
</script>

<style scoped>
.form-group {
  text-align: left;
}
</style>