<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="PARENT" />
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-md-6">
              <h3 class="page-title mb-0 text-left">
                Mes Enfants
                <div class="loader" id="loading_circle_1"></div>
              </h3>
            </div>
            <div class="col-md-6">
              <ul class="breadcrumb mb-0 p-0 float-right">
                <li class="breadcrumb-item">
                  <router-link to="/professor-dashboard"
                    ><i class="fas fa-home"></i> Accueil</router-link
                  >
                </li>
                <li class="breadcrumb-item"><span>TABLEAU DE BORD</span></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3"
                v-for="item_child in childrens" :key="item_child.id"
                data-toggle="modal" 
                v-bind:data-target="'#choose_section_and_period_'+item_child.id"
              >
              
                <div class="dash-widget dash-widget5"  >
                  <div class="dash-widget-info text-left d-inline-block">
                    
                    <h3>{{item_child.last_name}}
                      <br/> {{item_child.first_name}} </h3>
                    <span> {{item_child.student_classroom.classroom.name}} </span>
                    <br/>
                    <small><b> {{item_child.student_classroom.classroom.school.name}} </b></small>
                    
                  </div>
                  <span class="float-right"><img src="/template_admin/img/dash/dash-3.png" width="80" alt=""></span>
                </div>
            </div> -->

          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prenoms</th>
                <th>Classe</th>
                <th>Etablissement</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item_child in childrens" :key="'d_' + item_child.id">
                <td>{{ item_child.last_name }} {{ item_child.first_name }}</td>
                <td>{{ item_child.student_classroom.classroom.name }}</td>
                <td>
                  {{ item_child.student_classroom.classroom.school.name }}
                </td>
                <td>
                  <button
                    class="btn btn-success"
                    @click="
                      goToBulletin(
                        item_child.id,
                        item_child.student_classroom.classroom.id
                      )
                    "
                  >
                    Voir le bulletin
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../utils/Variable";
// import setAuthHeader from '../utils/setAuthHeader';

import BaseAdmin from "../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },
  data() {
    return {
      childrens: [],
      echec_connexion: false,
      erreur_de_chargement: false,
      year_period_id: null,
    };
  },
  mounted() {
    this.getChild();
  },
  methods: {
    getChild: function () {
      document.getElementById("loading_circle_1").style.display =
        "inline-block";
      this.erreur_de_chargement = false;
      axios
        .get(API_URL.ALL_PARENT_CHILD)
        .then((response) => {
          //   alert(response.data.message);
          console.log(response);
          this.childrens = response.data.data.children;
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          alert("Prbleme de connexion, une erreur est survenue");
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },
    goToBulletin(id_eleve, id_classe) {
      // if(this.year_period_id == null){
      //   alert("Choisissez la periode ");
      //   return false;
      // }
      // /:id_classe/:nom_matiere/:subject_id/:nom_period/:id_period
      // this.$router.replace("/parent-bulletin-eleve/"+id_eleve+"/"+id_classe+"/"+this.year_period_id);
      this.$router.replace(
        "/parent-bulletin-eleve/" + id_eleve + "/" + id_classe
      );
    },

    invalid_field() {
      if (
        this.prefix_mobile == "" ||
        this.prefix_mobile == null ||
        this.mobile == "" ||
        this.mobile == null ||
        this.password == "" ||
        this.password == null
      ) {
        alert("Veuillez remplir correctement les champs requis");
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
