<template>
	<div class="main-wrapper">

    <BaseAdmin user_type="PROFESSOR" />
    <div class="page-wrapper">
			<div class="content container-fluid">

				<div class="page-header">
					<div class="row">
						<div class="col-md-6">
							<h3 class="page-title mb-0 text-left">
                Enregistrer des Absences
      					<div class="loader" id="loading_circle_1"></div>
              </h3>
						</div>
						<div class="col-md-6">
							<ul class="breadcrumb mb-0 p-0 float-right">
								<li class="breadcrumb-item">
									<router-link to="/prof-dashboard"><i class="fas fa-home"></i> Accueil</router-link>
								</li>
								<li class="breadcrumb-item"><span>Enregistrer des Absences</span></li>
							</ul>
						</div>
					</div>
				</div>

				<div class="row">
                    <div class="offset-md-3 col-md-4 table-bordered p-3 ">
                      <h4 class="text-left"> Choisissez la classe </h4>
                      <select v-model="classe_choisi" class="form-control" >
                        <option v-for="item_classe in classrooms" :key="item_classe.id" v-bind:value="item_classe">
                          {{item_classe.classroom.name}} - {{item_classe.subject.name}}
                        </option>
                      </select>

                      <section v-if="classe_choisi.classroom.year_period.length >0" >
                        <br/>
                        <h4 class="text-left"> Choisissez la periode </h4>
                        <select v-model="year_period_id" class="form-control" >
                          <option v-for="item_period in classe_choisi.classroom.year_period" :key="item_period.id" v-bind:value="item_period.id">
                            {{item_period.name}}
                          </option>
                        </select>
                      </section>
                      <br/>
                      <h3 class="text text-right" >
                          <button class="btn btn-success" @click="goToNoterAbsenceEleve" > Continuer </button>
                      </h3>
                  </div>
           
				</div>
				
				
			</div>
		</div>
  </div>
</template>

<script>

import axios from 'axios';
import API_URL from '../../../../utils/Variable';
// import setAuthHeader from '../utils/setAuthHeader';

import BaseAdmin from '../../base-dashboard.vue';

export default {
  components:{
    BaseAdmin
  },
  data() {
    return {
      classrooms: [],
      classe_choisi: {
         classroom:{
              year_period : []
         }
      },
      echec_connexion:false,
      erreur_de_chargement:false,
      year_period_id : null,
    };
  },
  mounted(){
	  this.getClassroom();
  },
  methods: {
    
     getClassroom: function () {
      document.getElementById("loading_circle_1").style.display ="inline-block";
      this.erreur_de_chargement = false;
      axios
        .get(API_URL.ALL_PROF_CLASSROOM)
        .then((response) => {
        //   alert(response.data.message);
          console.log(response);
          this.classrooms = response.data.data;
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          alert("Prbleme de connexion, une erreur est survenue");
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },
    goToNoterAbsenceEleve(){
      if(this.year_period_id == null){
        alert("Choisissez la periode ");
        return false;
      }
      // /professor-enregistrer-notes/'+objet.id+'/'+nom_matiere+'/'+subject_id+'/'+year_period_id
        this.$router.replace(
            "/professor-enregistrer-absences/"
                +this.classe_choisi.classroom.id
                +"/"+this.classe_choisi.subject.name.replace('/','-')
                +"/"+this.classe_choisi.subject.id
                +"/"+this.year_period_id
            );
    },
  },
};
</script>
