<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="PROFESSOR" />
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-md-6">
              <h3 class="page-title mb-0 text-left">
                {{objet.school.name}}
                <br/>
                [{{objet.name}} - {{nom_matiere}} ] 
                <br/>
                ({{year_period_name}})
                
              </h3>
            </div>
            <div class="col-md-6">
              <ul class="breadcrumb mb-0 p-0 float-right">
                <li class="breadcrumb-item">
                  <router-link to="/prof-dashboard"><i class="fas fa-home"></i> Accueil</router-link>
                </li>
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-home"></i> Etablissement</a>
                </li>
                <li class="breadcrumb-item"><span>liste eleves</span></li>
              </ul>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-sm-12 text-left">
                    <a v-if="objet.id != undefined"
                        @click="$router.go(-1)"
                        class=" float-left"
                        >
                          &lt; Retour
                    </a>
                    <br/><br/>
                    <div class="page-title">
                     
                      <h4> Moyennes des eleves de {{objet.name}} en {{nom_matiere}} </h4>


                      <div class="loader" id="loading_circle_1"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table custom-table">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-left" >Nom et prenoms </th>
                        <th class="text-left" >Moyennes </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item_student_moyenne in students_moyenne" :key="item_student_moyenne.id" class="text-center" >
                        <td>
                          <h2>
                            {{item_student_moyenne.eleve}}
                          </h2>
                        </td>
                        <td>
                          <h2>
                            {{item_student_moyenne.moyenne}}
                          </h2>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    

  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../utils/Variable";
// import setAuthHeader from '../utils/setAuthHeader';

import BaseAdmin from "../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },
  data() {
    return {
      
      id : this.$route.params.id_classe,
      nom_period : this.$route.params.nom_period,
      year_period_id : this.$route.params.id_period,
      year_period_name : "",
      nom_matiere : this.$route.params.nom_matiere,
      subject_id : this.$route.params.subject_id,


      echec_connexion: false,
      erreur_de_chargement: false,
      objet: {
        school : {}
      },
      requete_reussi:false,
      message_erreur:"",
	    students:[],
	    students_moyenne:[],
    };
  },
  mounted() {
    this.getItemData();
    this.getAverage();
    this.getDetailsYearPeriod();
  },
  methods: {
    hideErrorMessage: function () {
      this.echec_connexion = false;
      this.erreur_de_chargement = false;
    },

     getItemData() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.CLASSROOM_STUDENT_LIST + "/" + this.id;
      axios
        .get(route)
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.objet = response.data.data.classroom;
            this.students = response.data.data.students;
            console.log(response.data.school);
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

     getDetailsYearPeriod() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.GET_YEAR_PERIOD + "/" + this.year_period_id;
      axios
        .get(route)
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.year_period_name = response.data.data.name;
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },
    getAverage(){
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.CONSULTER_MOYENNE+"?wrapped=1";
      axios
        .post(route,{
          subject_id : this.subject_id,
          classroom_id : this.id,
          year_period_id : this.year_period_id,
        })
        .then((response) => {
          console.log(response);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.students_moyenne = response.data.data.student_moyenne;
            console.log(response);
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    }


  },
};
</script>
