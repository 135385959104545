<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="FONDATOR" />
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-md-6">
              <h3 class="page-title mb-0 text-left">
                Liste des eleves [{{ objet.name }} - {{ objet.school.name }} ]
              </h3>
            </div>
            <div class="col-md-6">
              <ul class="breadcrumb mb-0 p-0 float-right">
                <li class="breadcrumb-item">
                  <router-link to="/fondator-dashboard"
                    ><i class="fas fa-home"></i> Accueil</router-link
                  >
                </li>
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-home"></i> Etablissement</a>
                </li>
                <li class="breadcrumb-item"><span>liste eleves</span></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-sm-6 text-left">
                    <router-link
                      v-if="objet.id != undefined"
                      v-bind:to="
                        '/fondator-details-etablissements/' + objet.school.id
                      "
                      class="float-left"
                    >
                      &lt; Retour
                    </router-link>
                    <br />
                    <br />
                    <div class="page-title">
                      <router-link
                        v-if="objet.id != undefined"
                        v-bind:to="
                          '/fondator-ajouter-eleves-etablissement/' + objet.id
                        "
                        class="btn btn-primary btn-rounded float-left"
                        ><i class="fas fa-plus"></i> Ajouter un eleves en
                        {{ objet.name }}</router-link
                      >
                      <div class="loader" id="loading_circle_1"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table custom-table">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-left">Nom et prenoms</th>

                        <th>
                          Inscription ({{ objet.subscription_amount
                          }}<small>F</small> )
                        </th>

                        <th>
                          Inscription Affecté ({{ objet.subscription_aft_amount
                          }}<small>F</small> )
                        </th>

                        <th>
                          Scolarité ({{ objet.tuition_amount
                          }}<small>F</small> )
                        </th>

                        <th>
                          Parent <br />
                          Telephone
                        </th>
                        <th class="text-right">#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item_student in students"
                        :key="item_student.id"
                        class="text-center"
                      >
                        <td>
                          <h2>
                            {{ item_student.first_name }}
                            {{ item_student.last_name }}
                          </h2>
                        </td>

                        <!-- Inscription -->
                        <td>
                          <span v-if="!item_student.is_affecte">
                           <span v-if="item_student.inscription.length > 0">
                              {{
                                item_student.inscription[
                                  item_student.inscription.length - 1
                                ]["remaining_amount"] == 0
                                  ? "Soldé"
                                  : "Relicat : " +
                                    item_student.inscription[
                                      item_student.inscription.length - 1
                                    ]["remaining_amount"] +
                                    "F"
                              }}
                            </span>

                            <span v-else> Aucun paiement </span>
                          </span>
                          <span v-else> Pas disponible </span>
                        </td>

                        <!-- Inscription Affecté -->
                        <td>
                          <span v-if="item_student.is_affecte">
                            <span v-if="item_student.inscription_aft.length > 0">
                              {{
                                item_student.inscription_aft[
                                  item_student.inscription_aft.length - 1
                                ]["remaining_amount"] == 0
                                  ? "Soldé"
                                  : "Relicat : " +
                                    item_student.inscription_aft[
                                      item_student.inscription_aft.length - 1
                                    ]["remaining_amount"] +
                                    "F"
                              }}
                            </span>

                            <span v-else> Aucun paiement </span>
                          </span>
                          <span v-else> Non affecté </span>
                        </td>

                        <td>
                          <span v-if="!item_student.is_affecte">
                            <span v-if="item_student.scolarite.length > 0">
                              {{
                                item_student.scolarite[
                                  item_student.scolarite.length - 1
                                ]["remaining_amount"] == 0
                                  ? "Soldé"
                                  : "Relicat : " +
                                    item_student.scolarite[
                                      item_student.scolarite.length - 1
                                    ]["remaining_amount"] +
                                    "F"
                              }}
                            </span>

                            <span v-else> Aucun paiement </span>
                          </span>
                          
                          <span v-else> Non disponible </span>
                        </td>
                        <td>
                          {{ item_student.parent.last_name }}
                          {{ item_student.parent.first_name }}
                          <br />
                          {{ item_student.parent.prefix_mobile }}
                          {{ item_student.parent.mobile }}
                        </td>
                        <td class="text-right">
                          <!-- Inscription -->
                          <span>
                            <div
                              class="modal custom-modal fade"
                              v-bind:id="'modal_inscription_' + item_student.id"
                            >
                              <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5>
                                      Inscription (historique de paiements) -
                                      {{ item_student.last_name_name }}
                                      {{ item_student.first_name }}
                                    </h5>
                                    <button
                                      type="button"
                                      class="close"
                                      data-dismiss="modal"
                                      aria-hidden="true"
                                    >
                                      &times;
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <div class="row text-center">
                                      <div class="col-md-7 text-left">
                                        <label>Nouveau versement</label>
                                        <input
                                          type="number"
                                          v-model="new_paiement_amount"
                                          class="form-control"
                                          placeholder="Montant"
                                        />
                                      </div>
                                      <div class="col-md-4">
                                        <br />
                                        <button
                                          type="button"
                                          @click="
                                            enregistrer_paiement(item_student.id, item_student.student_classroom.id, 'SUBSCRIPTION')
                                          "
                                          id="btn_submit"
                                          class="btn btn-primary"
                                        >
                                          Enregistrer
                                          <div
                                            class="loader"
                                            id="loading_circle_4"
                                          ></div>
                                        </button>
                                      </div>
                                    </div>
                                    <br /><br />
                                    <table class="table">
                                      <thead>
                                        <th>Date</th>
                                        <th>Montant</th>
                                        <th>Restant</th>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="item_paiement in item_student.inscription"
                                          :key="item_paiement.id"
                                        >
                                          <td>
                                            {{
                                              new Date(
                                                item_paiement.created_at
                                              ).toLocaleString()
                                            }}
                                          </td>
                                          <td>
                                            {{
                                              item_paiement.amount
                                            }}
                                            &nbsp;<small>F</small>
                                          </td>
                                          <td>
                                            {{
                                              item_paiement.remaining_amount
                                            }}
                                            &nbsp;<small>F</small>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <button
                              class="btn btn-primary"
                              data-toggle="modal"
                              v-bind:data-target="
                                '#modal_inscription_' + item_student.id
                              "
                              v-if="!item_student.is_affecte"
                            >
                              Inscription
                            </button>
                          </span>
                          &nbsp;&nbsp;

                          <!-- Scolarite -->
                          <span>
                            <div
                              class="modal custom-modal fade"
                              v-bind:id="'modal_scolarite_' + item_student.id"
                            >
                              <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5>
                                      Historique de paiements -
                                      {{ item_student.last_name_name }}
                                      {{ item_student.first_name }}
                                    </h5>
                                    <button
                                      type="button"
                                      class="close"
                                      data-dismiss="modal"
                                      aria-hidden="true"
                                    >
                                      &times;
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <div class="row text-center">
                                      <div class="col-md-7 text-left">
                                        <label>Nouveau versement</label>
                                        <input
                                          type="number"
                                          v-model="new_paiement_amount"
                                          class="form-control"
                                          placeholder="Montant"
                                        />
                                      </div>
                                      <div class="col-md-4">
                                        <br />
                                        <button
                                          type="button"
                                          @click="
                                            enregistrer_paiement(item_student.id, item_student.student_classroom.id, 'SCOLARITE')
                                          "
                                          id="btn_submit"
                                          class="btn btn-primary"
                                        >
                                          Enregistrer
                                          <div
                                            class="loader"
                                            id="loading_circle_4"
                                          ></div>
                                        </button>
                                      </div>
                                    </div>
                                    <br /><br />
                                    <table class="table">
                                      <thead>
                                        <th>Date</th>
                                        <th>Montant</th>
                                        <th>Restant</th>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="item_paiement in item_student.scolarite"
                                          :key="item_paiement.id"
                                        >
                                          <td>
                                            {{
                                              new Date(
                                                item_paiement.created_at
                                              ).toLocaleString()
                                            }}
                                          </td>
                                          <td>
                                            {{
                                              item_paiement.amount
                                            }}
                                            &nbsp;<small>F</small>
                                          </td>
                                          <td>
                                            {{
                                              item_paiement.remaining_amount
                                            }}
                                            &nbsp;<small>F</small>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <button
                              class="btn btn-primary"
                              data-toggle="modal"
                              v-bind:data-target="
                                '#modal_scolarite_' + item_student.id
                              "
                              v-if="!item_student.is_affecte"
                            >
                              Scolarite
                            </button>
                          </span>
                          
                          &nbsp;&nbsp;
                          <router-link
                            v-bind:to="
                              '/fondator-editer-eleves-etablissement/' +
                              item_student.id
                            "
                            class="btn btn-secondary btn-sm mb-1"
                          >
                            <i class="far fa-edit"></i>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../utils/Variable";
// import setAuthHeader from '../utils/setAuthHeader';

import BaseAdmin from "../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },
  data() {
    return {
      id: this.$route.params.id_classe,
      new_paiement_amount: null,
      echec_connexion: false,
      erreur_de_chargement: false,
      objet: {
        school: {},
      },
      requete_reussi: false,
      message_erreur: "",
      students: [],
    };
  },
  mounted() {
    this.getItemData();
  },
  methods: {
    toggle_div_reponse: function () {
      let div_reponse = document.getElementById("div_reponse");
      if (div_reponse.style.display == "none") {
        div_reponse.style.display = "block";
      } else {
        div_reponse.style.display = "none";
      }
    },

    hideErrorMessage: function () {
      this.echec_connexion = false;
      this.erreur_de_chargement = false;
    },

    getItemData() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      var route = API_URL.CLASSROOM_STUDENT_LIST + "/" + this.id;
      axios
        .get(route)
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.objet = response.data.data.classroom;
            this.students = response.data.data.students;
            console.log(response.data.school);
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },
    enregistrer_paiement(student_id, classroom_id, tuition_type) {
      if (this.invalid_field()) {
        return false;
      }

      document.getElementById("loading_circle_4").style.display =
        "inline-block";
      document.getElementById("btn_submit").disabled = true;

      axios
        .post(API_URL.STUDENT_NEW_PAIEMENT, {
          tuition_type: tuition_type,
          student_id: student_id,
          classroom_id: classroom_id,
          amount: this.new_paiement_amount,
        })
        .then((response) => {
          console.log(response);

          this.message_erreur = [];
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur.push(key + " : " + error_array[key]);
            });
            this.requete_reussi = false;
          } else {
            this.new_paiement_amount = "";
            this.getItemData();
            this.requete_reussi = true;
            this.msg_success = response.data.message;
          }
        })
        .catch(() => {
          alert("un probleme de connexion est survenue");
        })
        .finally(() => {
          document.getElementById("loading_circle_4").style.display = "none";
          document.getElementById("btn_submit").disabled = false;
          document.getElementById("btn_modal").click();
        });
    },
    invalid_field() {
      if (
        this.new_paiement_amount == null ||
        this.new_paiement_amount == 0 ||
        this.new_paiement_amount == ""
      ) {
        alert("Veuillez remplir correctement les champs requis");
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
