<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="ADMIN" />

    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
              <h5 class="text-uppercase mb-0 mt-0 page-title text-left">
                Liste des fondateurs
              </h5>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
              <ul class="breadcrumb float-right p-0 mb-0">
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-home"></i> Acceuil</a>
                </li>
                <li class="breadcrumb-item"><a href="#">Utilisateurs</a></li>
                <li class="breadcrumb-item"><span>Fondateurs</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header text-left">
                <router-link to="/admin-dashboard">
                  &lt; Retour a la liste
                </router-link>
                <br />
                <br />
                <router-link
                  to="/admin-ajouter-utilisateur/FONDATOR"
                  class="btn btn-primary btn-rounded float-left"
                  ><i class="fas fa-plus"></i> Ajouter un nouveau</router-link
                >
                <div class="loader" id="loading_circle_1"></div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table custom-table datatable">
                    <thead class="thead-light">
                      <tr class="text-left">
                        <th>Type</th>
                        <th>Nom et Prenoms</th>
                        <th>Telephone</th>
                        <!-- <th>Email</th> -->
                        <th>#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item_fondateur in fondateurs"
                        :key="item_fondateur.id"
                        class="text-left"
                      >
                        <td>{{ item_fondateur.user_type }}</td>
                        <td>
                          {{ item_fondateur.first_name }}
                          {{ item_fondateur.last_name }}
                        </td>
                        <td>
                          {{ item_fondateur.prefix_mobile }}
                          {{ item_fondateur.mobile }}
                        </td>
                        <!-- <td>{{item_fondateur.email}}</td> -->
                        <td>
                          <router-link
                            v-bind:to="
                              '/admin-editer-utilisateur/' + item_fondateur.id
                            "
                            class="btn btn-primary btn-sm m-1"
                          >
                            <!-- <i class="far fa-edit"></i> -->
                            Details
                          </router-link>

                          <!-- <button
                            type="submit"
                            data-toggle="modal"
                            data-target="#delete_employee"
                            class="btn btn-danger btn-sm m-1"
                          >
                            <i class="far fa-trash-alt"></i>
                          </button> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../../utils/Variable";
import BaseAdmin from "../../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },

  data() {
    return {
      fondateurs: [],
      erreur_de_chargement: false,
    };
  },
  mounted: function () {
    if (API_URL.is_admin || API_URL.is_moderator || API_URL.is_supervisor) {
      this.refreshData();
    } else {
      this.$router.replace("/connexion");
    }
  },
  methods: {
    refreshData() {
      document.getElementById("loading_circle_1").style.display =
        "inline-block";
      this.erreur_de_chargement = false;
      axios
        .get(API_URL.LISTE_FONDATEUR)
        .then((response) => {
          this.fondateurs = response.data.data.users;
          // alert(response.data.message);
          console.log(response.data.data);
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          console.log(error);
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },
    supprimer(id) {
      document.getElementById(id).style.display = "inline-block";
      axios
        .delete(API_URL.ACQUISITIONS + "/" + id)
        .then((response) => {
          alert(response.data.message);
          document.getElementById(id).style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          console.log(error);
          document.getElementById(id).style.display = "none";
        })
        .finally(() => {
          this.refreshData();
        });
    },
  },
};
</script>

