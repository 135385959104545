<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="ADMIN" />
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-md-6">
              <h3 class="page-title mb-0 text-left">Tableau de bord</h3>
            </div>
            <div class="col-md-6">
              <ul class="breadcrumb mb-0 p-0 float-right">
                <li class="breadcrumb-item">
                  <router-link to="#"
                    ><i class="fas fa-home"></i> Accueil</router-link
                  >
                </li>
                <li class="breadcrumb-item"><span>Tableau de bord</span></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-sm-6 text-left">
                    <div class="page-title">
                      <router-link
                        to="/admin-ajouter-etablissements"
                        class="btn btn-primary btn-rounded float-left"
                        ><i class="fas fa-plus"></i> Ajouter un
                        nouveau</router-link
                      >
                      <div class="loader" id="loading_circle_1"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table custom-table">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-left">Etablissements</th>
                        <th>FONDATEUR</th>
                        <th class="text-right">#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item_school in schools"
                        :key="item_school.id"
                        class="text-center"
                      >
                        <td>
                          <h2>
                            {{ item_school.name }}
                          </h2>
                        </td>
                        <td>
                          {{ item_school.user.first_name }}
                          {{ item_school.user.last_name }}
                        </td>
                        <td class="text-right">
                          <router-link
                            v-bind:to="
                              '/admin-editer-etablissements/' + item_school.id
                            "
                            class="btn btn-secondary btn-sm mb-1"
                          >
                            <i class="far fa-edit"></i> Editer
                          </router-link>
                          &nbsp;&nbsp;

                          <router-link
                            v-bind:to="
                              '/admin-editer-utilisateur/' + item_school.user.id
                            "
                            class="btn btn-primary btn-sm mb-1"
                          >
                            A propos du proprietaire
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../utils/Variable";
// import setAuthHeader from '../utils/setAuthHeader';

import BaseAdmin from "../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },
  data() {
    return {
      prefix_mobile: 225,
      mobile: null,
      password: null,
      echec_connexion: false,
      erreur_de_chargement: false,
      objet: {
        nb_ecole: 0,
        nb_eleves: 0,
        nb_prof: 0,
      },
      schools: [],
    };
  },
  mounted() {
    this.getSchools();
  },
  methods: {
    hideErrorMessage: function () {
      this.echec_connexion = false;
      this.erreur_de_chargement = false;
    },

    getSchools: function () {
      document.getElementById("loading_circle_1").style.display =
        "inline-block";
      this.erreur_de_chargement = false;
      axios
        .get(API_URL.ALL_SCHOOL)
        .then((response) => {
          //   alert(response.data.message);
          console.log(response);
          this.schools = response.data.data.schools;
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          alert("Prbleme de connexion, une erreur est survenue");
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

    invalid_field() {
      if (
        this.prefix_mobile == "" ||
        this.prefix_mobile == null ||
        this.mobile == "" ||
        this.mobile == null ||
        this.password == "" ||
        this.password == null
      ) {
        alert("Veuillez remplir correctement les champs requis");
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
