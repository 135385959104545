<template>
  <div class="main-wrapper">
    <BaseAdmin user_type="FONDATOR" />

    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
              <h5 class="text-uppercase mb-0 mt-0 page-title text-left">
                Details d'un etablissement
              </h5>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
              <ul class="breadcrumb float-right p-0 mb-0">
                <li class="breadcrumb-item">
                  <a href="#"><i class="fas fa-home"></i> Acceuil</a>
                </li>
                <li class="breadcrumb-item"><a href="#">Etablissements</a></li>
                <li class="breadcrumb-item"><span>Details</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header text-left">
                <h3>Classes et Scolarité</h3>
                <router-link to="/fondator-dashboard">
                  &lt; Retour a la liste
                </router-link>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <div class="page-content">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="card">
                          <div class="card-header">
                            <h3>
                              {{ objet.name }}
                              <div class="loader" id="loading_circle_1"></div>

                              <!-- espace de notif reponse -->
                              <div id="div_reponse" style="display: none">
                                <div
                                  class="alert alert-success text-center"
                                  v-if="requete_reussi == true"
                                >
                                  <h4>{{ msg_success }}</h4>

                                  <br />
                                  <button
                                    id="btn_modal"
                                    type="button"
                                    class="btn btn-success"
                                    @click="toggle_div_reponse"
                                  >
                                    J'ai compris
                                  </button>
                                </div>

                                <div v-if="requete_reussi == false">
                                  <div class="alert alert-danger">
                                    <h4>
                                      Les champs suivants sont invalides :
                                    </h4>
                                    <ul>
                                      <li
                                        v-for="msg in message_erreur"
                                        :key="msg"
                                      >
                                        {{ msg }}
                                      </li>
                                    </ul>

                                    <br />
                                    <div class="text-center">
                                      <button
                                        id="btn_modal"
                                        type="button"
                                        class="btn btn-danger"
                                        @click="toggle_div_reponse"
                                      >
                                        J'ai compris
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- //Fin espace notif reponse -->
                            </h3>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <button
                                id="btn_add_class"
                                type="button"
                                class="
                                  btn btn-primary btn-rounded
                                  float-left
                                  m-2
                                "
                                @click="clear_field"
                                data-toggle="modal"
                                data-target="#modal_add_classroom"
                              >
                                <i class="fas fa-plus"></i>
                                Ajouter une classe
                              </button>

                              <div class="col-md-12 p-3">
                                <table class="table">
                                  <thead>
                                    <th>Classe</th>
                                    <th>Inscription</th>
                                    <th>Inscription Affecté</th>
                                    <th>Scolarité</th>
                                    <th>Nombre Eleves</th>
                                    <th>*</th>
                                    <th>*</th>
                                    <th>*</th>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item_classe in objet.classrooms"
                                      :key="item_classe.id"
                                    >
                                      <!-- Classe -->
                                      <td>{{ item_classe.name }}</td>

                                      <!-- Inscription -->
                                      <td>
                                        {{ item_classe.subscription_amount }}
                                        <small>F</small>
                                      </td>

                                      <!-- Inscription Affecté -->
                                      <td>
                                        {{ item_classe.subscription_aft_amount }}
                                        <small>F</small>
                                      </td>

                                      <!-- Scolarité -->
                                      <td>
                                        {{ item_classe.tuition_amount }}
                                        <small>F</small>
                                      </td>

                                      <!-- Nombre d'élève -->
                                      <td>{{ item_classe.students_count }}</td>

                                      <!-- Actions -->
                                      <td>
                                        <button
                                          class="btn btn-secondary"
                                          @click="
                                            modal_edit_class(
                                              item_classe.id,
                                              item_classe.name,
                                              item_classe.tuition_amount,
                                              item_classe.subscription_amount,
                                              item_classe.subscription_aft_amount,
                                              item_classe.students_count
                                            )
                                          "
                                        >
                                          <i class="far fa-edit"></i>Modifier
                                        </button>
                                        &nbsp;&nbsp;
                                        <button
                                          class="btn btn-danger"
                                          v-if="item_classe.students_count < 1"
                                          data-toggle="modal"
                                          v-bind:data-target="
                                            '#modal_supprimer_classroom_' +
                                            item_classe.id
                                          "
                                        >
                                          <i class="far fa-trash-alt"></i>
                                          Supprimer
                                        </button>

                                        <div
                                          class="modal custom-modal fade"
                                          v-bind:id="
                                            'modal_supprimer_classroom_' +
                                            item_classe.id
                                          "
                                          v-if="item_classe.students_count < 1"
                                        >
                                          <div
                                            class="
                                              modal-dialog modal-dialog-centered
                                            "
                                          >
                                            <div class="modal-content">
                                              <div class="modal-header">
                                                <h4 class="modal-title">
                                                  SUPPRIMER
                                                </h4>
                                                <button
                                                  type="button"
                                                  class="close"
                                                  data-dismiss="modal"
                                                  aria-hidden="true"
                                                >
                                                  &times;
                                                </button>
                                              </div>
                                              <div class="modal-body">
                                                <div>
                                                  <div
                                                    class="alert alert-danger"
                                                  >
                                                    ACTION IRREVERSIBLE !!
                                                    <br />
                                                    Confirmer la suppression de
                                                    la classe :
                                                    <b>{{
                                                      item_classe.name
                                                    }}</b>
                                                    ?
                                                  </div>

                                                  <div
                                                    class="col-12 text-center"
                                                  >
                                                    <button
                                                      class="btn btn-default"
                                                      data-dismiss="modal"
                                                      aria-hidden="true"
                                                    >
                                                      NON
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                      class="btn btn-danger"
                                                      data-dismiss="modal"
                                                      aria-hidden="true"
                                                      @click="
                                                        supprimer_item(
                                                          item_classe.id
                                                        )
                                                      "
                                                    >
                                                      CONFIRMER
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>

                                      <td>
                                        <router-link
                                          class="btn btn-secondary"
                                          v-bind:to="
                                            '/fondator-association-prof-matiere-classes/' +
                                            item_classe.id
                                          "
                                        >
                                          Matieres enseignées
                                        </router-link>
                                      </td>
                                      <td>
                                        <router-link
                                          class="btn btn-primary"
                                          v-bind:to="
                                            '/fondator-liste-eleves-etablissement/' +
                                            item_classe.id
                                          "
                                        >
                                          Gestion des eleves
                                        </router-link>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal custom-modal fade" id="modal_add_classroom">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Ajouter une classe</h4>
              <button
                type="button"
                id="close_modal_classe"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div>
                <div class="form-group">
                  <label>Classe</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="item_classroom_to_update_name"
                  />
                </div>

                <div class="form-group">
                  <label>Inscription</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="item_classroom_to_update_subscription"
                  />
                </div>

                <div class="form-group">
                  <label>Inscription Affecté</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="item_classroom_to_update_subscription_aft"
                  />
                </div>

                <div class="form-group">
                  <label>Scolarité</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="item_classroom_to_update_tuition"
                  />
                </div>

                <div class="col-12">
                  <h3 class="text-center">
                    <div class="loader" id="loading_circle_5"></div>
                    <button
                      type="button"
                      class="btn btn-primary"
                      id="btn_submit_1"
                      @click="add_classroom"
                    >
                      Enregistrer
                    </button>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ///modal editer classe// -->
      <button
        id="btn_edit_class"
        type="button"
        style="display: none"
        data-toggle="modal"
        data-target="#modal_edit_classroom"
        class="btn btn-primary btn-block"
      >
        Modal Editer classe
      </button>

      <div class="modal custom-modal fade" id="modal_edit_classroom">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">
                Editer la classe {{ item_classroom_to_update_students_count }} :
                {{ item_classroom_to_update_name }}
              </h4>
              <button
                type="button"
                id="close_modal_classe2"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div>
                <div class="form-group">
                  <label>Classe</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="item_classroom_to_update_name"
                  />
                </div>

                <div class="form-group">
                  <label>Inscription</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="item_classroom_to_update_subscription"
                    v-if="item_classroom_to_update_students_count < 1"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-model="item_classroom_to_update_subscription"
                    v-if="item_classroom_to_update_students_count > 0"
                    disabled
                  />
                </div>

                <div class="form-group">
                  <label>Inscription Affecté</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="item_classroom_to_update_subscription_aft"
                    v-if="item_classroom_to_update_students_count < 1"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-model="item_classroom_to_update_subscription_aft"
                    v-if="item_classroom_to_update_students_count > 0"
                    disabled
                  />
                </div>

                <div class="form-group">
                  <label>Scolarité</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="item_classroom_to_update_tuition"
                    v-if="item_classroom_to_update_students_count < 1"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-model="item_classroom_to_update_tuition"
                    v-if="item_classroom_to_update_students_count > 0"
                    disabled
                  />
                </div>

                <div class="col-12">
                  <h3 class="text-center">
                    <div class="loader" id="loading_circle_4"></div>
                    <button
                      type="button"
                      class="btn btn-warning"
                      id="btn_submit"
                      @click="update_classroom"
                    >
                      Enregistrer
                    </button>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../../utils/Variable";
import BaseAdmin from "../base-dashboard.vue";

export default {
  components: {
    BaseAdmin,
  },

  data() {
    return {
      id: this.$route.params.id_etablissement,
      item_classroom_to_update_name: "",
      item_classroom_to_update_tuition: "",
      item_classroom_to_update_id: "",
      item_classroom_to_update_subscription: "",
      item_classroom_to_update_subscription_aft: "",
      item_classroom_to_update_students_count: 1,
      objet: {
        is_first_cycle: 1,
        is_second_cycle: 1,
        address: "",
        profesor: [],
        classrooms: [],
        sujects: [],
      },
      msg_success: "",
      erreur_de_chargement: false,
      requete_reussi: false,
      message_erreur: [],
    };
  },
  mounted() {
    this.getItemData();
  },
  methods: {
    toggle_div_reponse: function () {
      let div_reponse = document.getElementById("div_reponse");
      if (div_reponse.style.display == "none") {
        div_reponse.style.display = "block";
      } else {
        div_reponse.style.display = "none";
      }
    },

    getItemData() {
      this.erreur_de_chargement = false;
      document.getElementById("loading_circle_1").style.display =
        "inline-block";

      axios
        .get(
          API_URL.READ_SCHOOL +
            "/" +
            this.id +
            "?with-fondator=1&with-classroom=1&with-profesor=1"
        )
        .then((response) => {
          console.log(response);
          // alert(response.data.message);
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur +=
                "<li>" + key + " " + error_array[key] + "</li>";
            });
            this.message_erreur = "Un probleme de connexion est survenu";
          } else {
            this.objet = response.data.data.school;
            if (this.objet.is_second_cycle == true) {
              this.objet.is_second_cycle = 1;
            } else {
              this.objet.is_second_cycle = 0;
            }
            console.log(response.data.school);
          }
          document.getElementById("loading_circle_1").style.display = "none";
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          this.CONTENU_MODAL = "Petit probleme de connexion.";
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

    modal_edit_class(id_classe, nom_classe, montant_scolarite, montant_inscription, montant_inscription_affecte, nb_eleve) {
      this.item_classroom_to_update_id = id_classe;
      this.item_classroom_to_update_name = nom_classe;
      this.item_classroom_to_update_tuition = montant_scolarite;

      this.item_classroom_to_update_subscription = montant_inscription;
      this.item_classroom_to_update_subscription_aft = montant_inscription_affecte;

      this.item_classroom_to_update_students_count = nb_eleve;
      document.getElementById("btn_edit_class").click();
    },

    add_classroom() {
      document.getElementById("loading_circle_5").style.display =
        "inline-block";
      document.getElementById("btn_submit_1").disabled = true;

      var route_update_classroom =
        API_URL.UPDATE_CLASSROOM + "/" + this.id + "/add-classroom";
      axios
        .post(route_update_classroom, {
          name: this.item_classroom_to_update_name,
          tuition_amount: this.item_classroom_to_update_tuition,
          subscription_amount: this.item_classroom_to_update_subscription,
          subscription_aft_amount: this.item_classroom_to_update_subscription_aft,
          occurence: 1,
        })
        .then((response) => {
          console.log(response);

          this.message_erreur = [];
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur.push(key + " : " + error_array[key]);
            });
            this.requete_reussi = false;
          } else {
            this.requete_reussi = true;
            this.msg_success = response.data.message;
            this.getItemData();
          }
        })
        .catch(() => {
          alert("un probleme de connexion est survenue");
        })
        .finally(() => {
          document.getElementById("loading_circle_5").style.display = "none";
          document.getElementById("btn_submit_1").disabled = false;
          document.getElementById("btn_modal").click();
          document.getElementById("close_modal_classe").click();
          this.clear_field();
        });
    },

    supprimer_item: function (id_classe) {
      document.getElementById("loading_circle_1").style.display =
        "inline_block";

      var route_delete_classroom =
        API_URL.UPDATE_CLASSROOM +
        "/" +
        this.id +
        "/delete-classroom/" +
        id_classe;
      axios
        .delete(route_delete_classroom)
        .then((response) => {
          document.getElementById("loading_circle_1").style.display = "none";

          this.message_erreur = [];
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur.push(key + " : " + error_array[key]);
            });
            this.CONTENU_MODAL = this.message_erreur;
            this.requete_reussi = false;
            document.getElementById("btn_modal").click();
          } else {
            alert(response.data.message);
            this.getItemData();
          }
        })
        .catch(() => {
          this.erreur_de_chargement = true;
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },

    update_classroom() {
      if (this.invalid_field()) {
        return false;
      }
      document.getElementById("loading_circle_4").style.display =
        "inline-block";
      document.getElementById("btn_submit").disabled = true;

      var route_update_classroom =
        API_URL.UPDATE_CLASSROOM +
        "/" +
        this.id +
        "/update-classroom/" +
        this.item_classroom_to_update_id;
      axios
        .post(route_update_classroom, {
          name: this.item_classroom_to_update_name,
          tuition_amount: this.item_classroom_to_update_tuition,
          subscription_amount: this.item_classroom_to_update_subscription,
          subscription_aft_amount: this.item_classroom_to_update_subscription_aft,
        })
        .then((response) => {
          console.log(response);

          this.message_erreur = [];
          if (response.data.is_error) {
            let error_array = response.data.data;
            Object.keys(error_array).map((key) => {
              this.message_erreur.push(key + " : " + error_array[key]);
            });
            this.requete_reussi = false;
          } else {
            this.requete_reussi = true;
            this.msg_success = response.data.message;
            this.getItemData();
          }
        })
        .catch(() => {
          alert("un probleme de connexion est survenue");
        })
        .finally(() => {
          document.getElementById("loading_circle_4").style.display = "none";
          document.getElementById("btn_submit").disabled = false;
          document.getElementById("btn_modal").click();
          document.getElementById("close_modal_classe2").click();
        });
    },

    invalid_field() {
      if (
        this.item_classroom_to_update_name == "" ||
        this.item_classroom_to_update_tuition == "" ||
        this.item_classroom_to_update_id == "" ||
        this.item_classroom_to_update_subscription == "" ||
        this.item_classroom_to_update_subscription_aft == ""
      ) {
        alert("Veuillez remplir correctement les champs requis");
        return true;
      } else {
        return false;
      }
    },
    clear_field() {
      (this.item_classroom_to_update_name = ""), (this.item_classroom_to_update_tuition = ""), (this.item_classroom_to_update_subscription = ""), (this.item_classroom_to_update_subscription_aft = "");
    },
  },
};
</script>

<style scoped>
.form-group {
  text-align: left;
}
</style>