<template>
	<div class="main-wrapper">

    <BaseAdmin user_type="PROFESSOR" />
    <div class="page-wrapper">
			<div class="content container-fluid">

				<div class="page-header">
					<div class="row">
						<div class="col-md-6">
							<h3 class="page-title mb-0 text-left">
                TABLEAU DE BORD
      					<div class="loader" id="loading_circle_1"></div>
              </h3>
						</div>
						<div class="col-md-6">
							<ul class="breadcrumb mb-0 p-0 float-right">
								<li class="breadcrumb-item">
									<router-link to="/prof-dashboard"><i class="fas fa-home"></i> Accueil</router-link>
								</li>
								<li class="breadcrumb-item"><span>TABLEAU DE BORD</span></li>
							</ul>
						</div>
					</div>
				</div>

				<div class="row">
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3"
                v-for="item_classe in classrooms" :key="item_classe.id"
                @click="goToListeEleve(item_classe.classroom.id,item_classe.subject.id,item_classe.subject.name.replace('/','-'))"
              >
              
                <div class="dash-widget dash-widget5"  >
                  <div class="dash-widget-info text-left d-inline-block">
                    
                    <span>({{item_classe.classroom.students_count}} eleves)</span>
                    <h3>{{item_classe.classroom.name}}</h3>
                    <span>{{item_classe.subject.name}}</span>
                    
                  </div>
                  <span class="float-right"><img src="/template_admin/img/dash/dash-2.png" width="80" alt=""></span>
                </div>
            </div>
            
				</div>
				
				
			</div>
		</div>
  </div>
</template>

<script>

import axios from 'axios';
import API_URL from '../../../utils/Variable';
// import setAuthHeader from '../utils/setAuthHeader';

import BaseAdmin from '../base-dashboard.vue';

export default {
  components:{
    BaseAdmin
  },
  data() {
    return {
      classrooms: [],
      echec_connexion:false,
      erreur_de_chargement:false,
      year_period_id : null,
    };
  },
  mounted(){
	  this.getClassroom();
  },
  methods: {
    
     getClassroom: function () {
      document.getElementById("loading_circle_1").style.display ="inline-block";
      this.erreur_de_chargement = false;
      axios
        .get(API_URL.ALL_PROF_CLASSROOM)
        .then((response) => {
        //   alert(response.data.message);
          console.log(response);
          this.classrooms = response.data.data;
        })
        .catch((error) => {
          this.erreur_de_chargement = true;
          alert("Prbleme de connexion, une erreur est survenue");
          console.log(error);
        })
        .finally(() => {
          document.getElementById("loading_circle_1").style.display = "none";
        });
    },
    goToListeEleve(id_classe,subject_id,nom_matiere){
      this.$router.replace("/professor-liste-eleves/"+id_classe+"/"+nom_matiere+"/"+subject_id);
    },
    
    invalid_field() {
      if (
        this.prefix_mobile == "" ||
        this.prefix_mobile == null ||
        this.mobile == "" ||
        this.mobile == null ||
        this.password == "" ||
        this.password == null
      ) {
        alert("Veuillez remplir correctement les champs requis");
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
