<template>
  <div class="main-wrapper">
    <div class="header-outer">
      <div class="header">
        <!-- <a id="mobile_btn" class="mobile_btn float-left" href="#sidebar"> -->
        <a id="mobile_btn" class="mobile_btn float-left" @click="toggle_mobile_menu()">
          <i class="fas fa-bars" id="btn_toggle_mobile_menu" aria-hidden="true"></i>
        </a>
        <a id="toggle_btn" class="float-left" href="javascript:void(0);">
          <img src="/template_admin/img/sidebar/icon-21.png" alt="" />
        </a>

        <ul class="nav float-left">
          <li>
            <div class="top-nav-search">
              <a href="javascript:void(0);" class="responsive-search">
                <i class="fa fa-search"></i>
              </a>
            </div>
          </li>
          <li>
            <a
              href="/"
              class="mobile-logo d-md-block d-lg-none d-block"
              >
              <img
                src="/template_admin/img/logo1.png"
                alt=""
                width="30"
                height="30"/>
            </a>
          </li>
        </ul>

        <ul class="nav user-menu float-right">
          
          <li class="nav-item dropdown has-arrow">
            <a href="#" class="nav-link user-link" data-toggle="dropdown">
              
              <span v-if="user_type == 'ADMIN'"> ADMINISTRATEUR </span>
              <span v-else-if="user_type == 'FONDATOR'"> FONDATEUR </span>
              <span v-else-if="user_type == 'PROFESSOR'"> PROFESSEUR </span>
              <span v-else-if="user_type == 'PARENT'"> PARENT </span>
              <span v-else> UTILISATEUR </span>
            </a>
            <div class="dropdown-menu">
              <router-link class="dropdown-item" to="/admin-modifier-profil">Mon Profil</router-link>
              <a @click="deconexion" class="dropdown-item" href="#">Deconnexion</a>
            </div>
          </li>
        </ul>
        <div class="dropdown mobile-user-menu float-right">
          <a
            href="#"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false">
            <i class="fas fa-ellipsis-v"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <router-link class="dropdown-item" to="/admin-modifier-profil">Mon Profil</router-link>
              <a @click="deconexion" class="dropdown-item" href="#">Deconnexion</a>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar" id="sidebar">
      <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
          <div class="header-left">
            <a href="index.html" class="logo">
              <img
                src="/template_admin/img/lomeko-logo.jpeg"
                width="150"
                alt=""
              />
              <!-- <span class="text-uppercase">Lomeko School</span> -->
            </a>
          </div>
          <ul class="sidebar-ul">
            

            <!-- ADMIN ET ADJOINTS -->
            <li class="active text-left" v-if="is_admin || is_moderator || is_supervisor">
              <router-link to="/admin-dashboard"
                ><img
                  src="/template_admin/img/sidebar/icon-1.png"
                  alt="icon"
                /><span>Tableau de bord</span></router-link
              >
            </li>

            <section v-if="is_admin || is_moderator || is_supervisor">
            <!-- <li class="submenu text-left" v-if="is_admin || is_moderator || is_supervisor" >
              <a href="#"
                ><img src="/template_admin/img/sidebar/icon-2.png" alt="icon" />
                <span> Utilisateurs</span> <span class="menu-arrow"></span
              ></a>
              <ul class="list-unstyled text-left"> -->
                <li v-if="is_admin || is_moderator" class="text-left" >
                  <router-link to="/admin-liste-adjoints"
                    ><span>
                      <small>Gestion des Adjoints</small>
                    </span>
                  </router-link
                  >
                </li>
                <li class="text-left">
                  <router-link to="/admin-liste-fondateurs">
                    <span> <small>Gestion des Fondateurs</small>  </span>
                  </router-link>
                </li>
                <li class="text-left">
                  <router-link to="/admin-liste-etablissements">
                    <span> <small> Gestion d'Etablissements </small> </span>
                  </router-link>
                </li>
                
            </section>
              <!-- </ul> -->
            <!-- </li> -->

            <!-- FONDATOR -->
            <section v-if="is_fondator">
              <li class="active text-left">
                <router-link to="/fondator-dashboard"
                  ><img
                    src="/template_admin/img/sidebar/icon-1.png"
                    alt="icon"
                  /><span>Tableau de bord</span></router-link
                >
              </li>
              <li class="text-left">
                    <router-link to="/fondator-dashboard">
                      <span> <small> Gestion d'Etablissements </small> </span>
                    </router-link>
              </li>
           
              <li class="text-left">
                    <router-link to="/fondator-pivot-classes">
                      <span> <small> Classes et Frais Scolaire </small> </span>
                    </router-link>
              </li>
              
              <li class="text-left">
                    <router-link to="/fondator-pivot-professeurs">
                      <span> <small> Gestion des professeurs </small> </span>
                    </router-link>
              </li>
              
              <li class="text-left">
                    <router-link to="/fondator-pivot-matieres-enseignees">
                      <span> <small> Matieres enseignées </small> </span>
                    </router-link>
              </li>
              
              <li class="text-left">
                    <router-link to="/fondator-pivot-prof-eleves">
                      <span> <small> Eleves et Paiement </small> </span>
                    </router-link>
              </li>

            </section>

            <!-- PROFESSOR -->
            
            <section v-if="is_profesor">
              <li class="active text-left">
                <router-link to="/prof-dashboard"
                  ><img
                    src="/template_admin/img/sidebar/icon-1.png"
                    alt="icon"
                  /><span>Tableau de bord</span></router-link
                >
              </li> 
              <li class="text-left">
                    <router-link to="/professor-pivot-notes">
                      <span> <small> Enregsitrer des notes </small> </span>
                    </router-link>
              </li>
              <li class="text-left">
                    <router-link to="/professor-pivot-absences">
                      <span> <small> Enregsitrer des absences </small> </span>
                    </router-link>
              </li>
              <li class="text-left">
                    <router-link to="/professor-pivot-moyennes">
                      <span> <small> Consulter les moyennes </small> </span>
                    </router-link>
              </li>
              <li class="text-left">
                    <router-link to="/professor-pivot-bilan-moyennes">
                      <span> <small> Bilan Absences </small> </span>
                    </router-link>
              </li>
            </section>

            <!-- PROFESSOR -->

            <li class="active text-left" v-if="is_parent">
              <router-link to="/parent-dashboard"
                ><img
                  src="/template_admin/img/sidebar/icon-1.png"
                  alt="icon"
                /><span>Mes Enfants</span></router-link
              >
            </li>

            <li class="text-left" id="close_mobile_menu" >
              <a href="#" @click="toggle_mobile_menu()" >
                <button class="btn btn-danger" > X </button>
                &nbsp;&nbsp;
                <span>Fermer</span>
                </a>
            </li>
            

          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API_URL from '../../utils/Variable';
import setAuthHeader from "../../utils/setAuthHeader";

export default {
  name: "BaseAdmin",
  props: {
    user_type: String
  },
  data(){
    
    return {
      is_admin : false,
      is_moderator : false,
      is_supervisor : false,
      is_profesor : false,
      is_fondator : false,
      is_parent : false,
    }
  },

  mounted() {
    this.verifiedLogged();
    this.setAutorisation();
  },
  methods: {
    toggle_mobile_menu(){
      let sidebar_width = document.getElementById('sidebar').clientWidth;
      if(document.getElementById('sidebar').style.left != sidebar_width+"px"){
        // document.getElementById('sidebar').style.position = "fixed";
        document.getElementById('sidebar').style.left = sidebar_width+"px";
      }else{
        // document.getElementById('sidebar').style.position = "relative";
        document.getElementById('sidebar').style.left = "0px";
      }
      
      let sidebar_list = document.getElementsByClassName('sidebar-overlay');

        for(let i=0; i< sidebar_list.length;i++){
          sidebar_list[i].click();
        }

    },

    
    setAutorisation(){
      this.is_admin = API_URL.is_admin();
      this.is_moderator = API_URL.is_moderator();
      this.is_supervisor = API_URL.is_supervisor();
      this.is_fondator = API_URL.is_fondator();
      this.is_profesor = API_URL.is_profesor();
      this.is_parent = API_URL.is_parent();
    },
    deconexion() {
      localStorage.removeItem("token");
      localStorage.removeItem("user_type");
      document.location.href = "/connexion";
    },
    verifiedLogged() {
      let token = localStorage.getItem("token");
      let user_type = localStorage.getItem("user_type");
      if (token == null && user_type == null ) {
        let route_actuel = location.pathname.substr(1);
        if (route_actuel != "" && route_actuel != "connexion") {
          document.location.href = "/connexion";
        }
      }
      setAuthHeader(token);
    },
  },
};
</script>

<style scoped>
  #close_mobile_menu{
    display: none;
  }

  @media only screen and (max-width: 600px) {
    #close_mobile_menu{
      display: block;
    }
  }
</style>